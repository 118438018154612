import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Button as ButtonB } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Button,
    Text,
    Loader,
    View,
} from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import '../../App.css'
import { publishMessage } from '../../graphql/mutations';
import { onMessageReceived } from '../../graphql/subscriptions';
import { useTramiteDataGeneral } from '../../TramiteContext';
import ResumenCard from './ResumenCard';
const steps = ['Información', 'Formas de pago'];



export const FormDepositoResiduos = () => {
    const { data, updateData } = useTramiteDataGeneral()
    const [isLoading, setIsLoading] = useState(false)
    const [infoRFC, setInfoRFC] = useState(true);
    const [authToken, setAuthToken] = useState('')
    const [taskToken, setTaskToken] = useState('')
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [declaracionesImp, setDeclaracionesImp] = useState([]);
    const [calculoData, setCalculoData] = useState({})
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const validationSchema = yup.object().shape({
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        anno: yup.string().required("Este Campo es Obligatorio"),
        MesInicio: yup.string().required("Este Campo es Obligatorio"),
        MesFinal: yup.string().required("Este Campo es Obligatorio"),
        SaldoFavor: yup.string(),
        Compensacion: yup.string(),
        Multas: yup.string(),
        TotalToneladas: yup.number().required("Este Campo es Obligatorio").positive("Solo Numeros positivos"),


    });

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
        },
        initialValues: {
            RFC: '',
            anno: '',
            MesInicio: '',
            MesFinal: '',
            SaldoFavor: '',
            Compensacion: '',
            Multas: '',
            TotalToneladas: '',

        }

    });

    const validarRFC = async (RFC) => {
        //MOOA910828HM8 : for testing 
        let user = await Auth.currentAuthenticatedUser();
        const variables = {
            "id": "1234",
            "payload": {
                "validationRequested": true,
                "data": {
                    "id": "IAR",
                    "rfc": RFC
                },
                "type": "declaraciones"
            }
        };
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },

        });
        console.log("A VALIDATE", Data);
    }

    const declaracionesAnteriores = async () => {
        const variables = {
            "id": "1234",
            "payload": {
                "data": {
                    "periodicidad": {
                        "id": infoRFC.periodicidad.id,//1,
                        "tipoPeriodo": infoRFC.periodicidad.tipoPeriodo,//"MENSUAL",
                        "esActivo": true,
                        "valor": infoRFC.periodicidad.valor
                    },
                    "rfc": "MOOA910828HM8",
                    "padron": {
                        "id": infoRFC.padron.id,//58,
                        "nombre": infoRFC.padron.nombre,//"IMPUESTO DE EMISIÓN DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                        "activo": infoRFC.padron.activo,//true
                        "clave": "IAR"
                    },
                    "mdCaracteristicaId": infoRFC.mdCaracteristicaId//67666
                },
                authToken,
                taskToken,
                type: "declaraciones",
            },
        }
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });

        console.log("A VALIDATE DECL", Data);
    }
    const validateField = (value, setString = true) => {
        return value === "" || value === 0 ? null : setString ? `${value}` : Number(value)
    }
    const calculoImpuesto = async (data) => {
        console.log("DATA---", data);
        const variables = {
            "id": "1234",

            "payload": {
                "data": {

                    "padron": {

                        "id": 60, //ID del padrón(Interno) 

                        "clave": "IAR" //Clave del movimiento(Interno) 

                    },

                    "generaEstadoCuenta": true, //Bandera booleana para generar estado de cuenta(Interno) 

                    "mdCaracteristicaId": infoRFC.mdCaracteristicaId,//67511, //ID de la característica de impuesto(Interno) 

                    "declaraciones": { //Datos correspondientes a la declaración 

                        "rfc": validateField(data.RFC),//"MOOA910828HM8", //RFC del contribuyente (Interno) 

                        "annio": validateField(data.anno, false), //Año del periodo declarado (Usuario) 

                        "mesInicio": validateField(Number(data.MesInicio), false), //Mes inicio del periodo declarado (Usuario) 

                        "mesFin": validateField(Number(data.MesFinal), false), //Mes fin del periodo declarado (Usuario) 

                        "compensacion": validateField(data.Compensacion),//1, //Importe por compensación (Usuario) 

                        "saldoAFavor": validateField(data.SaldoFavor),//100.00, //Importe por saldo a favor (Usuario) 

                        "multas": validateField(data.Multas),//200.0, //Importe por multas (Usuario) 

                        "toneladasResiduos": validateField(data.TotalToneladas),//5.0 //Cantidad de toneladas almacenadas (Usuario) 

                    }

                },
                authToken,
                taskToken,
                type: "declaraciones",
            }
        }
        console.log("---Variables", variables);
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
    }
    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)

        return `${day}/${month}/${year}`;
    }

    const GenerarLigaPago = async () => {
        const user = await Auth.currentAuthenticatedUser();
        // metodoPago : pago1 : card - pago2 : - pago3 :
        // prepara conceptos con lo de calculo
        const conceptos = calculoData.conceptos.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
        })
        //preparar otras referencias
        const otrasRef = calculoData.estadoCuenta.referencias.map(referencia => {
            return { banco: referencia.recaudacion, convenio: "NO HAY DATO", referencia: referencia.referencia }
        })
        const variables = metodoPago === "pago1" ?
            {
                "id": "123",
                "payload": {
                    "paymentMethod": "card",
                    "email": "david.sosa@g-tech.io",
                    "tramite": infoRFC.padron.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                    "reference": `${calculoData.estadoCuenta.referencias[0].referencia}`,//"202300011471739766260",
                    "monto": `${calculoData.total}`,//"0.01",
                    authToken,
                    taskToken,
                    type: "declaraciones",
                }
            }
            :
            metodoPago === "pago2" ?
                {
                    "id": "123",
                    "payload": {

                        "paymentMethod": "spei",
                        "data": {

                            "loteId": `${calculoData.estadoCuenta.loteId}`,

                            "total_pagar": calculoData.calculos.total,

                            "tramite": infoRFC.padron.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",

                            "rfc": infoRFC.rfc,

                            "fecha_generacion": getCurrentDate(),

                            "fecha_vigencia": "19/10/2022",

                            "contribuyente": `${data.infoRFC.nombre}`,

                            "referencia": `${calculoData.estadoCuenta.referencias[0].referencia}`,

                            "otras_referencias": otrasRef

                        },

                        "email":  user.attributes.email,//"cesar.schietekat@g-tech.io",

                        "procedure": "referencia_pago"

                    }

                }
                :
                {
                    "id": "123",
                    "payload": {
                        "paymentMethod": "spei",
                        "data": {
                            "contribuyente": `${data.infoRFC.nombre}`,

                            "fecha_generacion": "19/10/2022",

                            "rfc": infoRFC.rfc,

                            "fecha_vigencia": "19/10/2022",

                            "movimiento": "Declaracion complementaria",

                            "num_empleados": 250,

                            "instrumento_pago": "referencia bancaria",

                            "base_impuesto": "5%",

                            "periodo": "Septiembre - Octubre",

                            "tipo_declaracion": "Mensual",

                            "otros_conceptos": conceptos,

                            "total_pagar": "60.00"

                        },

                        "email": user.attributes.email,//"cesar.schietekat@g-tech.io",

                        "procedure": "presupuesto"

                    },
                };
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
        console.log("Data :>> ", Data, variables);
    }

    const registroPagoSEI = async () => {
        const variables = {
            "id": "1234",
            "payload": {
                "data": {
                    "email": "david.sosa@g-tech.io",
                    "reference": "202300011471739766260",
                    "response": "approved",
                    "foliocpagos": "684776968",
                    "auth": "170826",
                    "cd_response": "00",
                    "cd_error": "",
                    "nb_error": "",
                    "time": "13:17:38",
                    "date": "21/09/2023",
                    "nb_company": "GOBIERNO DEL ESTADO DE DURANGO CSS",
                    "nb_merchant": "9142283 GOB DURANGO DIGITAL",
                    "cc_type": "DEBITO/COMPROPAGO/Visa",
                    "tp_operation": "VENTA",
                    "cc_name": "",
                    "cc_number": "5740",
                    "cc_expmonth": "09",
                    "cc_expyear": "28",
                    "amount": "0.01",
                    "emv_key_date": "",
                    "id_url": "4LT7W8F1",
                    "st_cr": "0",
                    "cc_mask": "421747XXXXXX5740",
                    "datos_adicionales": {
                        "data": {
                            "label": "Tramite",
                            "value": "IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA"
                        }
                    }
                },
                authToken,
                taskToken,
                type: "declaraciones",
            }
        }
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
    }

    const [message, setMessage] = useState(null);

    useEffect(() => {
        const subscription = API.graphql({ query: onMessageReceived, variables: { id: '1234' } })
            .subscribe({
                next: (event) => {
                    console.log("MANAGE ", event.value.data);
                    const receivedMessage = event.value.data.onMessageReceived;
                    //parse json on payload response. - authToken - taskToken --- rfc
                    setMessage(receivedMessage);
                    const dataRec = JSON.parse(receivedMessage.payload)
                    console.log("DATA rec", dataRec);
                    setTaskToken(dataRec.taskToken)
                    if (dataRec.step === "validarRfc") {
                        setAuthToken(dataRec.authToken)
                        setInfoRFC(dataRec.response)
                        updateData({ ...data, infoRFC: dataRec.response })
                    }
                    if (dataRec.step === "buscarDeclaraciones") {
                        // handleNext()
                        setDeclaracionesImp(dataRec.response.declaracionesImpPortal)
                        updateData({ infoRFC: data.infoRFC, declaracionesImp: dataRec.response })
                    }
                    if (dataRec.step === "calculo") {
                        setCalculoData(dataRec.response) //---- de aqui neceistamos tomar la referencia para generar la liga.
                        updateData({ ...data, infoRFC: infoRFC, declaracionesImp: declaracionesImp, calculo: dataRec.response })
                    }

                },
                error: (error) => {
                    console.error('Subscription error', error);
                },
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Impuestos al Depósito o Almacenamiento de Residuos </View>
                <View className='FormSubTitle'>--Emisión Contaminantes--.</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>
            <View className='ViewPrincipal'>
                <View className='FormContent'>

                            <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};


                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel  {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {activeStep === steps.length ?
                                        (
                                            <React.Fragment>
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    <label>Descripcion: Pulsa el boton para generar tu orden de pago.</label>
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ justifyContent: "space-between" }}>
                                                    <ButtonB
                                                        variant='secondary'
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}

                                                    >
                                                        Anterior
                                                    </ButtonB>

                                                    <ButtonB type='submit' variant='secondary'>
                                                        Enviar
                                                    </ButtonB>
                                                </Box>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {activeStep === 0 ?
                                                    <Box>
                                                        <Row className="mb-3 mt-3">
                                                            <Form.Group
                                                                as={Col}
                                                                controlId="validationFormikRFC"
                                                                className="position-relative"
                                                            >
                                                                <Form.Label >
                                                                    RFC*
                                                                </Form.Label>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="RFC"
                                                                        value={formik.values.RFC}
                                                                        onChange={formik.handleChange}
                                                                        isValid={formik.touched.RFC && !formik.errors.RFC}
                                                                        isInvalid={!!formik.errors.RFC && formik.touched.RFC}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                                        {formik.errors.RFC}
                                                                    </Form.Control.Feedback>
                                                                    <ButtonB variant='secondary' onClick={() => validarRFC(formik.values.RFC)}>Validar RFC</ButtonB>
                                                                </InputGroup>
                                                            </Form.Group>

                                                        </Row>


                                                        <Row className='mb-3'>
                                                            <Form.Group
                                                                as={Col}
                                                                controlId="validationFormik102"
                                                                className="position-relative"
                                                            >
                                                                <Form.Label > Año*</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="anno"
                                                                    value={formik.values.anno}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.anno && !formik.errors.anno}
                                                                    isInvalid={!!formik.errors.anno && formik.touched.anno}
                                                                />

                                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.anno}</Form.Control.Feedback>
                                                            </Form.Group>

                                                        </Row>
                                                        <Row className="mb-3">

                                                            <Form.Group
                                                                as={Col}
                                                                controlId="validationFormik102"
                                                                className="position-relative"
                                                            >
                                                                <Form.Label > Mes Inicio*</Form.Label>
                                                                <Form.Select

                                                                    name="MesInicio"
                                                                    value={formik.values.MesInicio}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.MesInicio && !formik.errors.MesInicio}
                                                                    isInvalid={!!formik.errors.MesInicio && formik.touched.MesInicio}

                                                                >
                                                                    <option>Seleccione...</option>
                                                                    <option value="1">Enero</option>
                                                                    <option value="2">Febrero</option>
                                                                    <option value="3">Marzo</option>
                                                                    <option value="4">Abril</option>
                                                                    <option value="5">Mayo</option>
                                                                    <option value="6">Junio</option>
                                                                    <option value="7">Julio</option>
                                                                    <option value="8">Agosto</option>
                                                                    <option value="9">Septiembre</option>
                                                                    <option value="10">Octubre</option>
                                                                    <option value="11">Noviembre</option>
                                                                    <option value="12">Diciembre</option>
                                                                </Form.Select>

                                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.MesInicio}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group
                                                                as={Col}
                                                                controlId="validationFormik102"
                                                                className="position-relative"
                                                            >
                                                                <Form.Label > Mes Fin*</Form.Label>
                                                                <Form.Select

                                                                    name="MesFinal"
                                                                    value={formik.values.MesFinal}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.MesFinal && !formik.errors.MesFinal}
                                                                    isInvalid={!!formik.errors.MesFinal && formik.touched.MesFinal}

                                                                >
                                                                    <option>Seleccione...</option>
                                                                    <option value="1">Enero</option>
                                                                    <option value="2">Febrero</option>
                                                                    <option value="3">Marzo</option>
                                                                    <option value="4">Abril</option>
                                                                    <option value="5">Mayo</option>
                                                                    <option value="6">Junio</option>
                                                                    <option value="7">Julio</option>
                                                                    <option value="8">Agosto</option>
                                                                    <option value="9">Septiembre</option>
                                                                    <option value="10">Octubre</option>
                                                                    <option value="11">Noviembre</option>
                                                                    <option value="12">Diciembre</option>
                                                                </Form.Select>

                                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.MesFinal}</Form.Control.Feedback>
                                                            </Form.Group>

                                                        </Row>
                                                        {/***DECLARACIONES ANterkiores */}
                                                        <Row className='mb-3'>
                                                            <ButtonB onClick={() => declaracionesAnteriores(formik.values)} style={{ width: "50%", margin: "auto" }} variant='secondary'>Declaración</ButtonB>
                                                        </Row>
                                                        <Row>
                                                            <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                                                {declaracionesImp.length !== 0 && declaracionesImp.map((dec, index) => {

                                                                    return (<li key={index} style={{ backgroundColor: "lightgray", margin: "1rem", listStyle: "none", display: "grid", gridTemplateColumns: "1fr", padding: "1rem", borderRadius: "10px" }}>
                                                                        <span>clave: {dec.mdCaracteristicasImpId}</span>
                                                                        <span>Periodo: {dec.periodo}</span>
                                                                        <span>Fecha de declaracion : {dec.fechaDeclaracion}</span>
                                                                        <span>Total: {dec.total}</span>



                                                                    </li>)
                                                                })}
                                                            </ul>
                                                        </Row>
                                                        <Row className='mb-3'>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label >
                                                                    Compensación
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    placeholder='Cantidad'
                                                                    name='Compensacion'
                                                                    value={formik.values.Compensacion}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.Compensacion && !formik.errors.Compensacion}
                                                                    isInvalid={!!formik.errors.Compensacion && formik.touched.Compensacion}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.Compensacion}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label >
                                                                    Saldo a Favor
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    placeholder='Cantidad'
                                                                    name='SaldoFavor'
                                                                    value={formik.values.SaldoFavor}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.SaldoFavor && !formik.errors.SaldoFavor}
                                                                    isInvalid={!!formik.errors.SaldoFavor && formik.touched.SaldoFavor}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.SaldoFavor}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className='mb-3'>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label >Multas</Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    placeholder='Cantidad'
                                                                    name='Multas'
                                                                    value={formik.values.Multas}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.Multas && !formik.errors.Multas}
                                                                    isInvalid={!!formik.errors.Multas && formik.touched.Multas}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.Multas}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className='mb-3'>
                                                            <Form.Group as={Col} className='position-relative'>
                                                                <Form.Label >Toneladas de Residuos</Form.Label>
                                                                <Form.Control
                                                                    type='number'
                                                                    placeholder='Cantidad'
                                                                    name='TotalToneladas'
                                                                    value={formik.values.TotalToneladas}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.TotalToneladas && !formik.errors.TotalToneladas}
                                                                    isInvalid={!!formik.errors.TotalToneladas && formik.touched.TotalToneladas}
                                                                />
                                                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.TotalToneladas}</Form.Control.Feedback>
                                                            </Form.Group>

                                                        </Row>

                                                    </Box>
                                                    :
                                                    activeStep === 1 &&
                                                    (<FormPagos metodoPago={metodoPago} setMetodoPago={setMetodoPago} />)
                                                }
                                            </React.Fragment>
                                        )

                                    }

                                </Box>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="d-grid gap-2">
                                        <ButtonB onClick={()=>{
                                            if(activeStep === steps.length - 1 ){
                                                GenerarLigaPago()
                                                return
                                            }
                                                handleNext()
                                        }} variant='secondary' disabled={isLoading}>
                                            {isLoading ?
                                                <>
                                                    Loading
                                                    <Loader
                                                    />
                                                </>
                                                :
                                                <>
                                                   {activeStep === steps.length - 1 ? 'Terminar' : 'Siguiente'}

                                                </>

                                            }
                                        </ButtonB>
                                    </Form.Group>
                                </Row>


                                <Row className="mb-3">
                                    <Form.Group as={Col} className="d-grid gap-2">
                                        <ButtonB onClick={handleBack}  variant="light">
                                            Regresar
                                        </ButtonB>
                                    </Form.Group>

                                </Row>



                            </Form>

                </View>

            </View>
            <ResumenCard calculo={calculoImpuesto}></ResumenCard>
        </>
    )
}
