import React, { useEffect, useState } from 'react'
import ResumenCard from './ResumenCard';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Button as ButtonB } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {Button,Text, Loader, View,} from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import '../../App.css'
import { publishMessage } from '../../graphql/mutations';
import { onMessageReceived } from '../../graphql/subscriptions';
import { useTramiteDataGeneral } from '../../TramiteContext';
const steps = ['Información', 'Formas de pago'];





export const FormExtraccionMateriales = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [infoRFC, setInfoRFC] = useState(true);
    const [authToken, setAuthToken] = useState('');
    const [taskToken, setTaskToken] = useState('');
    const [declaracionesImp, setDeclaracionesImp] = useState([]);
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [isLoading, setIsLoading] = useState(false)


    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const validationSchema = yup.object().shape({
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        periodoIni: yup.string().required("Este Campo es Obligatorio"),
        periodoFin: yup.string().required("Este Campo es Obligatorio"),
        tipoMaterial: yup.number().required("Este Campo es onligatorio"),
        mtsCubicos: yup.number().required("Este Campo es Obligatorio").positive("Solo Numeros positivos"),
        multas: yup.number().positive("Solo Numeros positivos"),


    });

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
        },
        initialValues: {
            RFC: '',
            periodoIni: '',
            periodoFin: '',
            tipoMaterial: '',
            mtsCubicos: '',
            multas: '',

        }

    });

    const validarRFC = async (RFC) => {
        //MOOA910828HM8 : for testing 
        let user = await Auth.currentAuthenticatedUser();
        const variables = {
            "id": "1234",
            "payload": {
                "validationRequested": true,
                "data": {
                    "id": "IAR",
                    "rfc": RFC
                },
                "type": "declaraciones"
            }
        };
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },

        });
        console.log("A VALIDATE", Data);
    }
    const declaracionesAnteriores = async () => {
        const variables = {
            "id": "1234",
            "payload": {
                "data": {
                    "periodicidad": {
                        "id": infoRFC.periodicidad.id,//1,
                        "tipoPeriodo": infoRFC.periodicidad.tipoPeriodo,//"MENSUAL",
                        "esActivo": true,
                        "valor": infoRFC.periodicidad.valor
                    },
                    "rfc": "MOOA910828HM8",
                    "padron": {
                        "id": infoRFC.padron.id,//58,
                        "nombre": infoRFC.padron.nombre,//"IMPUESTO DE EMISIÓN DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                        "activo": infoRFC.padron.activo//true
                    },
                    "mdCaracteristicaId": infoRFC.mdCaracteristicaId//67666
                },
                authToken,
                taskToken,
                type: "declaraciones",
            },
        }
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });

        console.log("A VALIDATE DECL", Data);
    }
    const validateField = (value, setString = true) => {
        return value === "" || value === 0 ? null : setString ? `${value}` : Number(value)
    }

    const calculoImpuesto = async (data) => {
        console.log("DATA---", data);
        const variables = {
            "id": "1234",

            "payload": {
                "data": {

                    "padron": {

                        "id": 60, //ID del padrón(Interno) 

                        "clave": "IAR" //Clave del movimiento(Interno) 

                    },

                    "generaEstadoCuenta": true, //Bandera booleana para generar estado de cuenta(Interno) 

                    "mdCaracteristicaId": infoRFC.mdCaracteristicaId,//67511, //ID de la característica de impuesto(Interno) 

                    "declaraciones": { //Datos correspondientes a la declaración 

                        "rfc": validateField(data.RFC),//"MOOA910828HM8", //RFC del contribuyente (Interno) 

                        "annio": validateField(data.anno, false), //Año del periodo declarado (Usuario) 

                        "mesInicio": validateField(Number(data.MesInicio), false), //Mes inicio del periodo declarado (Usuario) 

                        "mesFin": validateField(Number(data.MesFinal), false), //Mes fin del periodo declarado (Usuario) 

                        "compensacion": validateField(data.Compensacion),//1, //Importe por compensación (Usuario) 

                        "saldoAFavor": validateField(data.SaldoFavor),//100.00, //Importe por saldo a favor (Usuario) 

                        "multas": validateField(data.Multas),//200.0, //Importe por multas (Usuario) 

                        "toneladasResiduos": validateField(data.TotalToneladas),//5.0 //Cantidad de toneladas almacenadas (Usuario) 

                    }

                },
                authToken,
                taskToken,
                type: "declaraciones",
            }
        }
        console.log("---Variables", variables);
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
    }

    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Impuestos ecologicos - Preservación y restauración ecologica</View>
                <View className='FormSubTitle'>--Impuesto Extracción Materiales--</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>
            <View className='ViewPrincipal'>
                <View className='FormContent'>

                    <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep} className='mb-3'>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};


                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel  {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ?
                                (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            <label>Descripcion: Pulsa el boton para generar tu orden de pago.</label>
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ justifyContent: "space-between" }}>
                                            <ButtonB
                                                variant='secondary'
                                                disabled={activeStep === 0}
                                                onClick={handleBack}

                                            >
                                                Anterior
                                            </ButtonB>

                                            <ButtonB type='submit' variant='secondary'>
                                                Enviar
                                            </ButtonB>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {activeStep === 0 ?
                                            <Box>
                                                <Row className="mb-3 mt-3">
                                                    <Form.Group
                                                        as={Col}
                                                        controlId="validationFormikRFC"
                                                        className="position-relative"
                                                    >
                                                        <Form.Label >
                                                            RFC*
                                                        </Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                                type="text"
                                                                name="RFC"
                                                                value={formik.values.RFC}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.RFC && !formik.errors.RFC}
                                                                isInvalid={!!formik.errors.RFC && formik.touched.RFC}
                                                            />
                                                            <Form.Control.Feedback type="invalid" tooltip>
                                                                {formik.errors.RFC}
                                                            </Form.Control.Feedback>
                                                            <ButtonB variant='secondary' onClick={() => validarRFC(formik.values.RFC)}>Validar RFC</ButtonB>
                                                        </InputGroup>
                                                    </Form.Group>

                                                </Row>



                                                <Row className="mb-3">

                                                    <Form.Group
                                                        as={Col}
                                                        controlId="validationFormik102"
                                                        className="position-relative"
                                                    >
                                                        <Form.Label >Periodo Inicial*</Form.Label>
                                                        <Form.Select
                                                            type="text"
                                                            name="periodoIni"
                                                            value={formik.values.periodoIni}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.periodoIni && !formik.errors.periodoIni}
                                                            isInvalid={!!formik.errors.periodoIni && formik.touched.periodoIni}

                                                        >
                                                            <option>Seleccione...</option>
                                                            <option value="1">Enero</option>
                                                            <option value="2">Febrero</option>
                                                            <option value="3">Marzo</option>
                                                            <option value="4">Abril</option>
                                                            <option value="5">Mayo</option>
                                                            <option value="6">Junio</option>
                                                            <option value="7">Julio</option>
                                                            <option value="8">Agosto</option>
                                                            <option value="9">Septiembre</option>
                                                            <option value="10">Octubre</option>
                                                            <option value="11">Noviembre</option>
                                                            <option value="12">Diciembre</option>
                                                        </Form.Select>

                                                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors.periodoIni}</Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Col}
                                                        controlId="validationFormik102"
                                                        className="position-relative"
                                                    >
                                                        <Form.Label >Periodo Final*</Form.Label>
                                                        <Form.Select
                                                            type="text"
                                                            name="periodoFin"
                                                            value={formik.values.periodoFin}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.periodoFin && !formik.errors.periodoFin}
                                                            isInvalid={!!formik.errors.periodoFin && formik.touched.periodoFin}

                                                        >
                                                            <option>Seleccione...</option>
                                                            <option value="1">Enero</option>
                                                            <option value="2">Febrero</option>
                                                            <option value="3">Marzo</option>
                                                            <option value="4">Abril</option>
                                                            <option value="5">Mayo</option>
                                                            <option value="6">Junio</option>
                                                            <option value="7">Julio</option>
                                                            <option value="8">Agosto</option>
                                                            <option value="9">Septiembre</option>
                                                            <option value="10">Octubre</option>
                                                            <option value="11">Noviembre</option>
                                                            <option value="12">Diciembre</option>
                                                        </Form.Select>

                                                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors.periodoFin}</Form.Control.Feedback>
                                                    </Form.Group>
                                                   

                                                </Row>
                                                {/***DECLARACIONES ANterkiores */}
                                                <Row className='mb-3'>
                                                    <ButtonB onClick={() => declaracionesAnteriores(formik.values)} style={{ width: "50%", margin: "auto" }} variant='secondary'>Declaración</ButtonB>
                                                </Row>
                                                <Row>
                                                    <ul style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                                        {declaracionesImp.length !== 0 && declaracionesImp.map((dec, index) => {

                                                            return (<li key={index} style={{ backgroundColor: "lightgray", margin: "1rem", listStyle: "none", display: "grid", gridTemplateColumns: "1fr", padding: "1rem", borderRadius: "10px" }}>
                                                                <span>clave: {dec.mdCaracteristicasImpId}</span>
                                                                <span>Periodo: {dec.periodo}</span>
                                                                <span>Fecha de declaracion : {dec.fechaDeclaracion}</span>
                                                                <span>Total: {dec.total}</span>



                                                            </li>)
                                                        })}
                                                    </ul>
                                                </Row>
                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label >Tipo de Material*</Form.Label>
                                                        <Form.Control
                                                            type='number'
                                                            placeholder='Cantidad'
                                                            name='tipoMaterial'
                                                            value={formik.values.tipoMaterial}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.tipoMaterial && !formik.errors.tipoMaterial}
                                                            isInvalid={!!formik.errors.tipoMaterial && formik.touched.tipoMaterial}
                                                        />
                                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.tipoMaterial}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                           

                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label >
                                                        Metros Cúbicos Extraídos*
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='number'
                                                            placeholder='Cantidad'
                                                            name='mtsCubicos'
                                                            value={formik.values.mtsCubicos}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.mtsCubicos && !formik.errors.mtsCubicos}
                                                            isInvalid={!!formik.errors.mtsCubicos && formik.touched.mtsCubicos}
                                                        />
                                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.mtsCubicos}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row className='mb-3'>
                                                    <Form.Group as={Col} className='position-relative'>
                                                        <Form.Label >
                                                        Multas                                                     </Form.Label>
                                                        <Form.Control
                                                            type='number'
                                                            placeholder='Cantidad'
                                                            name='multas'
                                                            value={formik.values.multas}
                                                            onChange={formik.handleChange}
                                                            isValid={formik.touched.multas && !formik.errors.multas}
                                                            isInvalid={!!formik.errors.multas && formik.touched.multas}
                                                        />
                                                        <Form.Control.Feedback type='invalid' tooltip>{formik.errors.multas}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                            
                                            </Box>
                                            :
                                            activeStep === 1 &&
                                            (<FormPagos metodoPago={metodoPago} setMetodoPago={setMetodoPago}/>)
                                        }
                                    </React.Fragment>
                                )

                            }

                        </Box>
                        <Row className="mb-3 mt-3">
                            <Form.Group as={Col} className="d-grid gap-2">
                                <ButtonB onClick={handleNext} variant='secondary' disabled={isLoading}>
                                    {isLoading ?
                                        <>
                                            Loading
                                            <Loader
                                            />
                                        </>
                                        :
                                        <>
                                            SIGUIENTE

                                        </>

                                    }
                                </ButtonB>
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col} className="d-grid gap-2">
                                <ButtonB onClick={handleBack} variant="light">
                                    Regresar
                                </ButtonB>
                            </Form.Group>

                        </Row>



                    </Form>

                </View>

            </View>
            <ResumenCard calculo={calculoImpuesto}></ResumenCard>
        </>
    )
}
