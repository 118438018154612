import React,{useState} from 'react'
import {Row, Col, Form, Button} from "react-bootstrap"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { View } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';

import { useNavigate } from 'react-router-dom';
import "../../App.css";
const steps = ['Información', 'Formas de pago'];
export const FormaActaReconocimiento = () => { 
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set()); 
    const [metodoPago, setMetodoPago] = useState('pago1');
    
    const handleNext = () => {
        let newSkipped = skipped; 
        setActiveStep((preActiveStep) => preActiveStep + 1); 
        setSkipped(newSkipped); 
    };

    const handleBack = () => {
        setActiveStep((preActiveStep) => preActiveStep - 1); 
    };

    const validationSchema = yup.object().shape({ 
        nombre : yup.string().required("Este campo es obligatorio"),
        primerApellido : yup.string().required("Este campo es obligatorio"),
        segundoApellido : yup.string(),
        fechaReconocimiento : yup.date().required("Este campo es obligatorio"),
    });
    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            console.log(values);
        },
        initialValues: {
            nombre: "",
            primerApellido: "",
            segundoApellido: "",
            fechaReconocimiento: ""
        }
    });

    return (
        <>
         <div  className='HeaderTramite'>
                     <View className='FormTitle'>Expedición copia Acta de Reconocimiento</View>
                     <View className='FormSubTitle'>Copia Acta de Reconocimiento</View>
                     <View className='FormDescripcion'>
                         A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                         emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                         territorio del Estado de Durango, México.
                     </View>
                 </div>
                 <View className="ViewPrincipal">
                     <View className="ViewContent">
                         <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                         <Box sx={{ width: '100%' }}>
                                 <Stepper activeStep={activeStep} className='mb-3'>
                                     {steps.map((label, index) => {
                                         const stepProps = {};
                                         const labelProps = {};
     
     
                                         return (
                                             <Step key={label} {...stepProps}>
                                                 <StepLabel  {...labelProps}>{label}</StepLabel>
                                             </Step>
                                         );
                                     })}
                                 </Stepper>
                                 {activeStep === steps.length ?
                                     (
                                         <React.Fragment>
                                             <Typography sx={{ mt: 2, mb: 1 }}>
                                                 <label>Descripcion: Pulsa el boton para generar tu orden de pago.</label>
                                             </Typography>
                                             <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ justifyContent: "space-between" }}>
                                                 <Button
                                                     variant='secondary'
                                                     disabled={activeStep === 0}
                                                     onClick={handleBack}
     
                                                 >
                                                     Anterior
                                                 </Button>
     
                                                 <Button type='submit' variant='secondary'>
                                                     Enviar
                                                 </Button>
                                             </Box>
                                         </React.Fragment>
                                     ) : (
                                         <React.Fragment>
                                             {activeStep === 0 ?
                                                 <Box>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Nombre (s)*</Form.Label>
                                     <Form.Control
                                         type='text'
                                         name='nombre'
                                         placeholder='Nombre (s)'
                                         value={formik.values.nombre}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.nombre && !formik.errors.nombre}
                                         isInvalid={formik.touched.nombre && !!formik.errors.nombre}
                                     />
                                     
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.nombre}</Form.Control.Feedback>
                                 </Form.Group>
                              
                             </Row>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Primer Apellido*</Form.Label>
                                     <Form.Control
                                         type='text'
                                         name='primerApellido'
                                         placeholder='Primer Apellido'
                                         value={formik.values.primerApellido}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.primerApellido && !formik.errors.primerApellido}
                                         isInvalid={formik.touched.primerApellido && !!formik.errors.primerApellido}
                                     />
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.primerApellido}</Form.Control.Feedback>
                                 </Form.Group>
                             </Row>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Segundo Apellido</Form.Label>
                                     <Form.Control
                                         type='text'
                                         name='segundoApellido'
                                         placeholder='Segundo Apellido'
                                         value={formik.values.segundoApellido}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.segundoApellido && !formik.errors.segundoApellido}
                                         isInvalid={formik.touched.segundoApellido && !!formik.errors.segundoApellido}
                                     />
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.segundoApellido}</Form.Control.Feedback>
                                 </Form.Group>
                             </Row>
                             <Row className='mb-3'>
                                 <Form.Group as={Col} className='position-relative'>
                                     <Form.Label>Fecha de Reconocimiento*</Form.Label>
                                     <Form.Control
                                         type='date'
                                         name='fechaReconocimiento'
                                         value={formik.values.fechaReconocimiento}
                                         onChange={formik.handleChange}
                                         isValid={formik.touched.fechaReconocimiento && !formik.errors.fechaReconocimiento}
                                         isInvalid={formik.touched.fechaReconocimiento && !!formik.errors.fechaReconocimiento}
                                     />
                                     <Form.Control.Feedback type='invalid' tooltip>{formik.errors.fechaReconocimiento}</Form.Control.Feedback>
                                 </Form.Group>
                             </Row>
                             </Box>
                                                 :
                                                 activeStep === 1 &&
                                                 (<FormPagos metodoPago={metodoPago} setMetodoPago={setMetodoPago}/>)
                                             }
                                         </React.Fragment>
                                     )
     
                                 }
     
                             </Box>
                             <Row className="mb-3 mt-3">
                                 <Form.Group as={Col} className="d-grid gap-2">
                                     <Button type='submit' variant='secondary' >SIGUIENTE</Button>
                                 </Form.Group>
                             </Row>
     
     
                             <Row className="mb-3">
                                 <Form.Group as={Col} className="d-grid gap-2">
                                     <Button onClick={() => navigate("/")} variant="light"> Regresar</Button>
                                 </Form.Group>
     
                             </Row>
                         </Form>
                     </View>
                 </View>
                 <aside >
                         
                         <div className="seccion1">
                             <span>DESGLOSE DEL IMPORTE A PAGAR</span>
                           <label style={{display:"flex", justifyContent:"space-between"}}>Conceptos<strong>Total</strong></label>
                         </div>
                        
                         <div className="seccion2">
                            
                         <span>PAGO 1 <strong> $104.75</strong></span>
                         <span>PAGO 2 <strong> $41.75</strong></span>
                         <span>PAGO 3 <strong> $0.75</strong></span>
                         </div>
                         <div className='seccion3' >
                         <span style={{ fontWeight: "bold" }}>TOTAL A PAGAR <strong> $147.25</strong></span> 
     
                         </div>
                     </aside>
        </>
       )
            
}