import React, { useState, useEffect } from 'react'
import { FormularioTramite } from '../components/FormularioTramite'
import { listFormularios } from '../graphql/queries';
import { API } from 'aws-amplify';
import { View } from '@aws-amplify/ui-react';
import { Button as ButtonB } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap'
import * as formik from 'formik';
import * as yup from 'yup';
import { deleteFormularios, updateFormularios } from '../graphql/mutations';
import '../App.css'
export const CreateForm = () => {
  const [listForm, setListForm] = useState(undefined);
  const [show, setShow] = useState(false);
  const [showD, setShowD] = useState(false);
  const [show2, setShow2] = useState(false);
  const [info, setInfo] = useState({});
  const [textC, setTextC] = useState({});
  const [idForm, setIdForm] = useState("");
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = (item) => {
    setShow(true);
    setInfo(item);
    setTextC(item.campos);
  };
  const handleShow2 = () => {
    setShow2(true);

  };
  async function fetchForms() {

    const apiData = await API.graphql({
      query: listFormularios
    });
    const transFromAPI = apiData.data.listFormularios.items;
    setListForm(transFromAPI);
    console.log(transFromAPI);

  }
  useEffect(() => {
    fetchForms();

  }, [])
  function deleteFormulario(id) {
    setIdForm(id);
    setShowD(true);
  }

  return (
    <>
      <View>
        <div style={{ textAlign: "right", width: "90%" }}>

          <ButtonB className='mt-3' onClick={() => handleShow2()}>Add Form</ButtonB>
        </div>

        <FormularioTramite fetchForms={fetchForms} show2={show2} setShow2={setShow2} />
        {listForm !== undefined ?
          <ul style={{ width: "90%", textAlign: "center", margin: "auto", padding: "0" }}>
            {listForm.map((item) => (

              <li className='mt-3 mb-3 ListForms' key={item.id}>
                <label style={{ margin: "10px" }}>Tramite:<br />  <strong>{item.tramite}</strong> </label>
                <label style={{ margin: "10px" }}>Metodo:<br />  <strong>{item.metodo}</strong></label>
                <label style={{ margin: "10px" }}>Api:<br /> <strong>{item.api}</strong> </label>
                <label style={{ margin: "10px" }}>Dependencia:<br />  <strong>{item.dependencia}</strong> </label>
                <div className='row mb-3'>
                  <label >Campos: </label>
                  <textarea
                    disabled={true}
                    name="Campos"

                    value={item.campos}
                    cols="auto"
                    style={{ resize: "none" }}
                  >
                  </textarea>
                </div>
                <div >
                  <ButtonB style={{ margin: "20px" }} variant='primary' onClick={() => handleShow(item)}>Editar</ButtonB>
                  <ButtonB variant='danger' onClick={() => deleteFormulario(item.id)}>Eliminar</ButtonB>
                </div>
              </li>
            ))}
          </ul>
          :
          null
        }

      </View>
      <ModalDelete fetchForms={fetchForms} showD={showD} setShowD={setShowD} idForm={idForm} />
      <ModalEdit handleClose={handleClose} show={show} info={info} fetchForms={fetchForms} textC={textC} setTextC={setTextC} />
    </>
  )
}



const ModalDelete = (props) => {
  const { idForm, fetchForms, showD, setShowD } = props;
  const handleClose = () => setShowD(false);
  async function deleteFormulario(id) {
    await API.graphql({
      query: deleteFormularios,
      variables: {
        input: {
          id: id
        }
      },

    });
    fetchForms();
    setShowD(false);
  }
  return (
    <Modal show={showD} >
      <Modal.Header closeButton>
        <Modal.Title>Eliminar</Modal.Title>
      </Modal.Header>
      <Modal.Body>Esta seguro de quiere eliminar el formulario</Modal.Body>
      <Modal.Footer>
        <ButtonB variant="primary" onClick={handleClose}>
          No
        </ButtonB>
        <ButtonB variant="secondary" onClick={() => deleteFormulario(idForm)}>
          Si
        </ButtonB>
      </Modal.Footer>
    </Modal>
  );
}





const ModalEdit = (props) => {
  const { handleClose, show, info, fetchForms, textC, setTextC } = props;
  const [disableB, setDisableB] = useState(true);
  const { Formik } = formik;
  const schema = yup.object().shape({
    UID: yup.string(),
    Dependencia: yup.string().required("Este Campo es Obligatorio"),
    Tramite: yup.string().required("Este Campo es Obligatorio"),
    Campos: yup.string()
        .test('valid-json', 'JSON inválido', (value) => {
        try {
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      })  
      .required('Campo requerido'),
    Api: yup.string().required("Este Campo es Obligatorio"),
    Metodo: yup.string().required("Este Campo es Obligatorio"),
  });
  async function updateFormulario(valores) {
    await API.graphql({
      query: updateFormularios,
      variables: {
        input: {
          id: valores.UID,
          dependencia: valores.Dependencia,
          tramite: valores.Tramite,
          campos: valores.Campos,
          api: valores.Api,
          metodo: valores.Metodo
        }
      },

    });
    fetchForms();
  }

  const AgregarBotonS = (text) => {
    const position = text.length - 3;
    const addetxt = `,"buttonS": {
    "name": "buttonS",
    "label": "",
    "type": "field",
    "renderer": "button",
    "htmlClass":"d-grid gap-2 mb-3",
    "fieldClass": "btn-secondary",
    "content": "Siguiente",
    "buttonType": "submit"
}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    setDisableB(true);


  }
  const AgregarBotonR = (text) => {
    const position = text.length - 3;
    const addetxt = `,"buttonR": {
    "name": "buttonR",
    "label": "",
    "type": "field",
    "renderer": "button",
    "htmlClass":"d-grid gap-2 mb-3",
    "fieldClass": "btn-light",
    "content": "Regresar",
    "buttonType": ""
}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    setDisableB(true);

  }
  const AgregarDiv = (text) => {
    const position = text.length - 3;
    const addetxt = `, "div": {
      "type": "container",
      "renderer": "div",
      "htmlClass": "row",
  "elements":{}}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    setDisableB(false);
  }
  const AgregarCampoD = (text) => {
    const position = text.length - 5;
    const addetxt = `, "Name": {
                "name": "Name",
                "htmlClass":"mb-3 text-start position-relative col",
                "labelClass":"text-decoration-underline fs-4",
                "label": "name",
                "type": "field",
                "renderer": "text",
                "fieldType": "text",
                "errorAs":"div",
                "errorClass":"invalid-tooltip",
                "validation": [
                    ["string"],
                    ["required", "Este campo es requerido"]
                  ]
            } `;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);

  }
  const AgregarCampo = (text) => {
    const position = text.length - 3;
    const addetxt = `, "Name": {
                "name": "Name",
                "htmlClass":"mb-3 text-start position-relative",
                "labelClass":"text-decoration-underline fs-4",
                "label": "name",
                "type": "field",
                "renderer": "text",
                "fieldType": "text",
                "errorAs":"div",
                "errorClass":"invalid-tooltip",
                "validation": [
                    ["string"],
                    ["required", "Este campo es requerido"]
                  ]
            } `;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    setDisableB(true);

  }

  const AgregarSelect = (text) => {
    const position = text.length - 3;
    const addetxt = `, "Name": {"type": "field",
    "renderer": "react-select",
    "name": "Name",
    "htmlClass":"mb-3 text-start position-relative col",
    "labelClass":"text-decoration-underline fs-4",
    "label": "Name",
    "isCreatable": true,
    "options": [
        {
            "value": "",
            "label": ""
        },
        {
            "value": "",
            "label": ""
        },
        {
            "value": "",
            "label": ""
        }
      
    ] ,   "errorClass":"invalid-tooltip",
                "validation": [
                    ["string"],
                    ["required", "Este campo es requerido"]
                  ]}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);
    setDisableB(true);

  }
  const AgregarSelectD = (text) => {
    const position = text.length - 5;
    const addetxt = `, "Name": {"type": "field",
    "renderer": "react-select",
    "name": "Name",
    "htmlClass":"mb-3 text-start position-relative col",
    "labelClass":"text-decoration-underline fs-4",
    "label": "Name",
    "isCreatable": true,
    "options": [
        {
            "value": "",
            "label": ""
        },
        {
            "value": "",
            "label": ""
        },
        {
            "value": "",
            "label": ""
        }
      
    ] ,   "errorClass":"invalid-tooltip",
                "validation": [
                    ["string"],
                    ["required", "Este campo es requerido"]
                  ]}`;
    const part1 = text.slice(0, position);
    const part2 = text.slice(position);
    const result = part1 + addetxt + part2;
    setTextC(result);

  }

  return (
    <Modal show={show} size='xl'>
      <Modal.Header >
        <Modal.Title>Edita Formulario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(valores, { resetForm }) => {
            handleClose();
            updateFormulario(valores);
          }

          }
          enableReinitialize={true}
          initialValues={{
            UID: info.id,
            Dependencia: info.dependencia,
            Tramite: info.tramite,
            Campos: textC,
            Api: info.api,
            Metodo: info.metodo,
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="FormActa">
              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    UID
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="UID"
                    value={values.UID}
                    onChange={handleChange}
                    isValid={touched.UID && !errors.UID}
                    isInvalid={!!errors.UID && touched.UID}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.UID}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Dependencia
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Dependencia"
                    value={values.Dependencia}
                    onChange={handleChange}
                    isValid={touched.Dependencia && !errors.Dependencia}
                    isInvalid={!!errors.Dependencia && touched.Dependencia}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Dependencia}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Tramite
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Tramite"
                    value={values.Tramite}
                    onChange={handleChange}
                    isValid={touched.Tramite && !errors.Tramite}
                    isInvalid={!!errors.Tramite && touched.Tramite}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Tramite}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">

                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Api
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Api"
                    value={values.Api}
                    onChange={handleChange}
                    isValid={touched.Api && !errors.Api}
                    isInvalid={!!errors.Api && touched.Api}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Api}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label>
                    Metodo
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Metodo"
                    value={values.Metodo}
                    onChange={handleChange}
                    isValid={touched.Metodo && !errors.Metodo}
                    isInvalid={!!errors.Metodo && touched.Metodo}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Metodo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Label>Campos Simples</Form.Label>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB onClick={() => AgregarCampo(values.Campos)} variant='outline-primary'>Agregar Campo</ButtonB>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB onClick={() => AgregarSelect(values.Campos)} variant='outline-primary'>Agregar Select</ButtonB>

                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Label>Campos del dentro del Div</Form.Label>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB onClick={() => AgregarDiv(values.Campos)} variant='outline-primary'>Agregar Div</ButtonB>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB disabled={disableB} onClick={() => AgregarCampoD(values.Campos)} variant='outline-primary'>Agregar campo al div</ButtonB>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB disabled={disableB} onClick={() => AgregarSelectD(values.Campos)} variant='outline-primary'>Agregar select al div</ButtonB>

                </Form.Group>

              </Row>
              <Row className='mb-3'>
                <Form.Label>Tipos de botones</Form.Label>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB onClick={() => AgregarBotonS(values.Campos)} variant='outline-primary'>boton Siguiente</ButtonB>

                </Form.Group>
                <Form.Group as={Col} className='d-grid gap-2'>

                  <ButtonB onClick={() => AgregarBotonR(values.Campos)} variant='outline-primary'>Agregar Regresar</ButtonB>

                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                  <Form.Label >
                    Campos
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={10}
                    style={{ resize: "none" }}
                    name="Campos"
                    value={values.Campos}
                    onChange={handleChange}
                    isValid={touched.Campos && !errors.Campos}
                    isInvalid={!!errors.Campos && touched.Campos}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.Campos}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="d-grid gap-2">
                  <ButtonB type="submit" variation="primary">
                    Enviar
                  </ButtonB>
                  <ButtonB onClick={() => handleClose()} variant='danger'>Cancelar</ButtonB>
                </Form.Group>

              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>

    </Modal>
  )
}