import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import { API, Auth } from "aws-amplify";
import Row from 'react-bootstrap/Row';
import { InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'; 
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import ResumenCard from './ResumenCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import {
    Button,
    Loader,
    View,
} from "@aws-amplify/ui-react";


const steps = ['Información', 'Pago'];


export const FormImpuestoHospedaje = () => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [skipped, setSkipped] = React.useState(new Set());

    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const validationSchema = yup.object().shape({
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        anno: yup.string().required("Este Campo es Obligatorio"),
        nombre: yup.string().required("Este Campo es Obligatorio"),
        mes: yup.string().required("Este Campo es Obligatorio"),
        base: yup.string().required("Este Campo es Obligatorio"),
        compensacion: yup.string(),
        saldoFavor: yup.string(),
        
        // TotalToneladas: yup.number().required("Este Campo es Obligatorio").positive("Solo Numeros positivos"),
    
    
    });

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            handleNext()
        },
        initialValues: {
            RFC: '',
            nombre: '',
            mes: '',
            anno: '',
            base: '',
            compensacion: '',
            saldoFavor: '',
        }
    
    });
    
    const validarRFC = async (RFC) => {
        //MOOA910828HM8 : for testing 
        let user = await Auth.currentAuthenticatedUser();
        const variables = {
            "id": "1234",
            "payload": {
                "validationRequested": true,
                "data": {
                    "id": "IAR",
                    "rfc": RFC
                },
                "type": "declaraciones"
            }
        };
        const Data = await API.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
    
        });
        console.log("A VALIDATE", Data);
    }



    return(
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Impuesto sobre Hospedaje - ISH </View>
                <View className='FormSubTitle'>--Impuesto Bebidas Alcoholicas--</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>

            <View className='ViewPrincipal'>
                <View className='FormContent'>
                    <Form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    className="ViewActa"
                    >
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel  {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      <label>
                        Descripcion: Pulsa el boton para generar tu orden de
                        pago.
                      </label>
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="secondary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Anterior
                      </Button>

                      <Button type="submit" variant="secondary">
                        Enviar
                      </Button>
                    </Box>
                  </React.Fragment>
                    ) : (
                            <React.Fragment>
                                 {activeStep === 0 ? (
                                <Box>
                                    <Col>
                                        <Row className='mb-3'>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Nombre*</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="nombre"
                                                    value={formik.values.nombre}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.nombre && !formik.errors.nombre}
                                                    isInvalid={!!formik.errors.nombre && formik.touched.nombre}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.anno}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3 mt-3">
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormikRFC"
                                                className="position-relative"
                                            >
                                                <Form.Label >
                                                    RFC*
                                                </Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="text"
                                                        name="RFC"
                                                        value={formik.values.RFC}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.RFC && !formik.errors.RFC}
                                                        isInvalid={!!formik.errors.RFC && formik.touched.RFC}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formik.errors.RFC}
                                                    </Form.Control.Feedback>
                                                    <ButtonB variant='secondary' onClick={() => validarRFC(formik.values.RFC)}>Validar RFC</ButtonB>
                                                </InputGroup>
                                            </Form.Group>

                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Mes*</Form.Label>
                                                <Form.Select

                                                    name="periodo"
                                                    value={formik.values.mes}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.mes && !formik.errors.mes}
                                                    isInvalid={!!formik.errors.mes && formik.touched.mes}

                                                >
                                                    <option>Seleccione...</option>
                                                    <option value="1">Enero</option>
                                                                    <option value="2">Febrero</option>
                                                                    <option value="3">Marzo</option>
                                                                    <option value="4">Abril</option>
                                                                    <option value="5">Mayo</option>
                                                                    <option value="6">Junio</option>
                                                                    <option value="7">Julio</option>
                                                                    <option value="8">Agosto</option>
                                                                    <option value="9">Septiembre</option>
                                                                    <option value="10">Octubre</option>
                                                                    <option value="11">Noviembre</option>
                                                                    <option value="12">Diciembre</option>
                                                    
                                                </Form.Select>

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.mes}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Año*</Form.Label>
                                                <Form.Control
                                                        type="number"
                                                        name="anno"
                                                        value={formik.values.anno}
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched.anno && !formik.errors.anno}
                                                        isInvalid={!!formik.errors.anno && formik.touched.anno}
                                                    />
                                                    

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.anno}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Base del Impuesto*</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="base"
                                                    value={formik.values.base}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.base && !formik.errors.base}
                                                    isInvalid={!!formik.errors.base && formik.touched.base}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.base}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Compensacion</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="incentivos"
                                                    value={formik.values.compensacion}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.compensacion && !formik.errors.compensacion}
                                                    isInvalid={!!formik.errors.compensacion && formik.touched.compensacion}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.compensacion}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row className='mb-3'>
                                            <Form.Group
                                                as={Col}
                                                controlId="validationFormik102"
                                                className="position-relative"
                                            >
                                                <Form.Label > Saldo a Favor</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="incentivos"
                                                    value={formik.values.saldoFavor}
                                                    onChange={formik.handleChange}
                                                    isValid={formik.touched.saldoFavor && !formik.errors.saldoFavor}
                                                    isInvalid={!!formik.errors.saldoFavor && formik.touched.saldoFavor}
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>{formik.errors.saldoFavor}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        
                                        
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                </Box>
                                ) : (
                                    activeStep === 1 && (
                                      <FormPagos
                                        metodoPago={metodoPago}
                                        setMetodoPago={setMetodoPago}
                                      />
                                    )
                                  )}
                            </React.Fragment>
                            )}
                        </Box>
                        <Row className="mb-3">
                                    <Form.Group as={Col} className="d-grid gap-2">
                                        <ButtonB onClick={handleNext} variant='secondary' isDisabled={isLoading}>
                                            {isLoading ?
                                                <>
                                                    Loading
                                                    <Loader
                                                    />
                                                </>
                                                :
                                                <>
                                                    SIGUIENTE

                                                </>

                                            }
                                        </ButtonB>
                                    </Form.Group>
                                </Row>


                                <Row className="mb-3">
                                    <Form.Group as={Col} className="d-grid gap-2">
                                        <ButtonB onClick={handleBack}  variant="light">
                                            Regresar
                                        </ButtonB>
                                    </Form.Group>

                                </Row>
                    </Form>
                </View>
            </View>
            <ResumenCard calculoImp={""} />
        </>
    );
}