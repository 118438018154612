import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import {
    Button,
} from "@aws-amplify/ui-react";
import { API } from 'aws-amplify';
import { createFormularios } from '../graphql/mutations';


export const FormularioTramite = (props) => {
    const { fetchForms, show2, setShow2 } = props;
    const [textC, setTextC] = useState('{"schema":{"renderer":"form","id":"basic","label":"Basic", "subTitle":"", "descripcion":"","type":"container","htmlClass":"ViewActa","elements":{}}}');
    const [disableB, setDisableB]= useState(true);
   
    const { Formik } = formik;

    const handleClose = () => {
        setShow2(false)
        setTextC('{"schema":{"renderer":"form","id":"basic","label":"Basic","subTitle":"", "descripcion":"","type":"container","htmlClass":"ViewActa","elements":{}}}')

    };

    const schema = yup.object().shape({
        Dependencia: yup.string().required("Este Campo es Obligatorio"),
        Tramite: yup.string().required("Este Campo es Obligatorio"),
        Campos: yup.string()
             .test('valid-json', 'JSON inválido', (value) => {
                try {
                    JSON.parse(value);
                    return true;
                } catch (error) {
                    return false;
                }
            }) 
            .required('Campo requerido'),
        Api: yup.string().required("Este Campo es Obligatorio"),
        Metodo: yup.string().required("Este Campo es Obligatorio"),
    });

    async function crearFormulario(valores) {
        await API.graphql({
            query: createFormularios,
            variables: {
                input: {
                    dependencia: valores.Dependencia,
                    tramite: valores.Tramite,
                    campos: valores.Campos,
                    api: valores.Api,
                    metodo: valores.Metodo
                }
            },

        });
        fetchForms();
    }

    const AgregarBotonS = (text) => {
        const position = text.length - 3;
        const addetxt = `,"buttonS": {
        "name": "buttonS",
        "label": "",
        "type": "field",
        "renderer": "button",
        "htmlClass":"d-grid gap-2 mb-3",
        "fieldClass": "btn-secondary",
        "content": "Siguiente",
        "buttonType": "submit"
    }`;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);
        setDisableB(true);

    }
    const AgregarBotonR = (text) => {
        const position = text.length - 3;
        const addetxt = `,"buttonR": {
        "name": "buttonR",
        "label": "",
        "type": "field",
        "renderer": "button",
        "htmlClass":"d-grid gap-2 mb-3",
        "fieldClass": "btn-light",
        "content": "Regresar",
        "buttonType": ""
    }`;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);
        setDisableB(true);

    }
    const AgregarDiv = (text) => {
        const position = text.length - 3;
        const addetxt = `, "div": {
          "type": "container",
          "renderer": "div",
          "htmlClass": "row",
      "elements":{}}`;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);
        setDisableB(false);
    }
    const AgregarCampoD = (text) => {
        const position = text.length - 5;
        const addetxt = `, "Name": {
                    "name": "Name",
                    "htmlClass":"mb-3 text-start position-relative col",
                    "labelClass":"text-decoration-underline fs-4",
                    "label": "name",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "errorAs":"div",
                    "errorClass":"invalid-tooltip",
                    "validation": [
                        ["string"],
                        ["required", "Este campo es requerido"]
                      ]
                } `;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);

    }
    const AgregarCampo = (text) => {
        const position = text.length - 3;
        const addetxt = `, "Name": {
                    "name": "Name",
                    "htmlClass":"mb-3 text-start position-relative",
                    "labelClass":"text-decoration-underline fs-4",
                    "label": "name",
                    "type": "field",
                    "renderer": "text",
                    "fieldType": "text",
                    "errorAs":"div",
                    "errorClass":"invalid-tooltip",
                    "validation": [
                        ["string"],
                        ["required", "Este campo es requerido"]
                      ]
                } `;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);
        setDisableB(true);

    }

    const AgregarSelect = (text) => {
        const position = text.length - 3;
        const addetxt = `, "Name": {"type": "field",
        "renderer": "react-select",
        "name": "Name",
        "htmlClass":"mb-3 text-start position-relative col",
        "labelClass":"text-decoration-underline fs-4",
        "label": "Name",
        "isCreatable": true,
        "options": [
            {
                "value": "",
                "label": ""
            },
            {
                "value": "",
                "label": ""
            },
            {
                "value": "",
                "label": ""
            }
          
        ] ,   "errorClass":"invalid-tooltip",
                    "validation": [
                        ["string"],
                        ["required", "Este campo es requerido"]
                      ]}`;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);
        setDisableB(true);

    }
    const AgregarSelectD = (text) => {
        const position = text.length - 5;
        const addetxt = `, "Name": {"type": "field",
        "renderer": "react-select",
        "name": "Name",
        "htmlClass":"mb-3 text-start position-relative col",
        "labelClass":"text-decoration-underline fs-4",
        "label": "Name",
        "isCreatable": true,
        "options": [
            {
                "value": "",
                "label": ""
            },
            {
                "value": "",
                "label": ""
            },
            {
                "value": "",
                "label": ""
            }
          
        ] ,   "errorClass":"invalid-tooltip",
                    "validation": [
                        ["string"],
                        ["required", "Este campo es requerido"]
                      ]}`;
        const part1 = text.slice(0, position);
        const part2 = text.slice(position);
        const result = part1 + addetxt + part2;
        setTextC(result);

    }



    return (
        <>
            <Modal show={show2} size='xl'>
                <Modal.Header >
                    <Modal.Title>Edita Formulario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(valores, { resetForm }) => {
                            console.log("Valores", valores);
                            crearFormulario(valores);
                            resetForm();
                            handleClose();
                            setTextC('{"schema":{"renderer":"form","id":"basic","label":"Basic","type":"container","htmlClass":"ViewActa","elements":{}}}')
                        }

                        }
                        enableReinitialize={true}
                        initialValues={{
                            Dependencia: '',
                            Tramite: '',
                            Campos: textC,
                            Api: '',
                            Metodo: '',
                        }}

                    >
                        {({ handleSubmit, handleChange, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit} className="FormActa">
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                                        <Form.Label >
                                            Dependencia
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Dependencia"
                                            value={values.Dependencia}
                                            onChange={handleChange}
                                            isValid={touched.Dependencia && !errors.Dependencia}
                                            isInvalid={!!errors.Dependencia && touched.Dependencia}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.Dependencia}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                                        <Form.Label >
                                            Tramite
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Tramite"
                                            value={values.Tramite}
                                            onChange={handleChange}
                                            isValid={touched.Tramite && !errors.Tramite}
                                            isInvalid={!!errors.Tramite && touched.Tramite}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.Tramite}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">

                                    <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                                        <Form.Label >
                                            Api
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Api"
                                            value={values.Api}
                                            onChange={handleChange}
                                            isValid={touched.Api && !errors.Api}
                                            isInvalid={!!errors.Api && touched.Api}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.Api}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                                        <Form.Label>
                                            Metodo
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="Metodo"
                                            value={values.Metodo}
                                            onChange={handleChange}
                                            isValid={touched.Metodo && !errors.Metodo}
                                            isInvalid={!!errors.Metodo && touched.Metodo}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.Metodo}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label>Campos Simples</Form.Label>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB onClick={() => AgregarCampo(values.Campos)} variant='outline-primary'>Agregar Campo</ButtonB>

                                    </Form.Group>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB onClick={() => AgregarSelect(values.Campos)} variant='outline-primary'>Agregar Select</ButtonB>

                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label>Campos del dentro del Div</Form.Label>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB onClick={() => AgregarDiv(values.Campos)} variant='outline-primary'>Agregar Div</ButtonB>

                                    </Form.Group>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB disabled={disableB} onClick={() => AgregarCampoD(values.Campos)} variant='outline-primary'>Agregar campo al div</ButtonB>

                                    </Form.Group>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB disabled={disableB} onClick={() => AgregarSelectD(values.Campos)} variant='outline-primary'>Agregar select al div</ButtonB>

                                    </Form.Group>

                                </Row>
                                <Row className='mb-3'>
                                    <Form.Label>Tipos de botones</Form.Label>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB onClick={() => AgregarBotonS(values.Campos)} variant='outline-primary'>boton Siguiente</ButtonB>

                                    </Form.Group>
                                    <Form.Group as={Col} className='d-grid gap-2'>

                                        <ButtonB onClick={() => AgregarBotonR(values.Campos)} variant='outline-primary'>Agregar Regresar</ButtonB>

                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationFormik104" className="position-relative" >
                                        <Form.Label >
                                            Campos
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows={10}
                                            style={{ resize: "none" }}
                                            name="Campos"
                                            value={values.Campos}
                                            onChange={handleChange}
                                            isValid={touched.Campos && !errors.Campos}
                                            isInvalid={!!errors.Campos && touched.Campos}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            {errors.Campos}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="d-grid gap-2">
                                        <Button type="submit" variation="primary">
                                            Enviar
                                        </Button>
                                        <Button variation='danger' onClick={() => handleClose()}>Cancelar</Button>
                                    </Form.Group>

                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal >
        </>
    )
}
