import React, { useState } from 'react';
import logoDurango from "../assets/DurGobEst.png"
import { useAuthenticator } from '@aws-amplify/ui-react';
import ProfilesPage from './Profiles';
import { GrUserManager } from 'react-icons/gr';
import { Icon } from "@aws-amplify/ui-react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Auth } from 'aws-amplify';
import { CloseButton } from 'react-bootstrap';
import { Menu, MenuItem, MenuButton } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Header.css';


const Header = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [visible, setVisible] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const handleCloseMenu = () => setVisibleM(false);
  const navigate = useNavigate();


  async function signOut() {
    try {
      await Auth.signOut();
      window.location.href = "/"
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <>
      <Navbar expand={'md'} className=" mb-3">
        <Container fluid>
          <Navbar.Toggle onClick={() => setVisibleM(true)} style={{ border: "unset", backgroundColor: "transparent" }} />
          <Navbar.Brand href="../"><img src={logoDurango} alt="Logo-Durango" width={186} height={46} /> </Navbar.Brand>
          <Navbar.Offcanvas

            placement="end"
            show={visibleM}
            style={{ backgroundColor: "#D4E0E0" }}
          >
            <Offcanvas.Header >

              <Offcanvas.Title >
                <div>
                  <img src={logoDurango} alt="Logo-Durango" width={186} height={46} />
                </div>
              </Offcanvas.Title>
              <CloseButton onClick={handleCloseMenu} />
            </Offcanvas.Header>
            <Offcanvas.Body style={{ backgroundColor: "#D4E0E0" }}>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="https://dev.pagos.durango.gob.mx/" id='NavLink'  rel="noopener noreferrer">Inicio</Nav.Link>
                <Nav.Link href='https://dev.pagos.durango.gob.mx/' rel="noopener noreferrer" id='NavLink'>Trámites y Servicios</Nav.Link>
                <Nav.Link href="https://dev.pagos.durango.gob.mx/" rel="noopener noreferrer" id='NavLink'>Directorio</Nav.Link>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Menu
          menuAlign="end"
            trigger={
              <MenuButton  
              backgroundColor="#343D45"
              color="white"
              variation='link'
                borderRadius="20px"
              >
               Usuario
              </MenuButton>
            }
          >
{/*             <MenuItem className='MenuItemUser' onClick={() => setVisible(true)}>Perfiles</MenuItem>
 */}            <MenuItem className='MenuItemUser' onClick={() => navigate("/tramites-servicios")}>Historial</MenuItem>
            <MenuItem className='MenuItemUser' onClick={() => navigate("/Configuracion")}>Configuración</MenuItem>
            <MenuItem className='MenuItemUser' onClick={() => { user ? signOut() : navigate("/login") }}>Cerrar Sesión</MenuItem>
          </Menu>
          {/*  <NavDropdown
                   title={<span><Icon as={GrUserManager} /></span>}
                    id={`offcanvasNavbarDropdown-expand-md`}
                    drop='down'
                    style={{textAlign:"center"}}
                    
                  >
                    <NavDropdown.Item onClick={() => setVisible(true)}>Perfiles</NavDropdown.Item>
                    <NavDropdown.Item  onClick={() => window.location.href = "/CreateForm"}>
                      Formulario
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => { user ? signOut() : window.location.href = "/login" }}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown> */}
          <ProfilesPage visible={visible} setVisible={setVisible} />
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
