import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from "aws-amplify";
import {
    Button,
    Divider,
    Flex,
    Heading,
    Text,
    TextField,
    View,
    withAuthenticator,
} from "@aws-amplify/ui-react";
import { listTransacciones } from "../graphql/queries";

import {
    createPerfiles,
    deletePerfiles,
} from "../graphql/mutations";

import { listPerfiles } from "../graphql/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";

import '../App.css'
const data = [

    {
        descripcion: "Expedicion Acta de nacimiento",
        monto: 49,
        fecha: "10/18/22 at 8:52 AM",
        tStatus: "En proceso",
        vigencia: "15/10/22"
    },
    {
        descripcion: "Expedicion Acta de nacimiento",
        monto: 49,
        fecha: "10/18/22 at 8:52 AM",
        tStatus: "Completado",
        vigencia: "15/10/22"
    }
]



const ProfilesPage = (props) => {
    const [profiles, setProfiles] = useState([]);
    const { visible, setVisible } = props;

    const handleClose = () => setVisible(false);

    useEffect(() => {
        fetchProfiles();
        fetchTrans();
    }, [])

    async function fetchProfiles() {
        let user = await Auth.currentAuthenticatedUser();
        console.log("USER DATA", user.username);
        const filter = {
            owner: {
                eq: user.username
            }
        };
        const apiData = await API.graphql({
            query: listPerfiles,
            variables: {
                filter: filter
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Adjust auth mode based on your setup
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        const profilesFromAPI = apiData.data.listPerfiles.items;
        setProfiles(profilesFromAPI);
    }
    async function createPerfil(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            name: form.get("name"),
            description: form.get("description"),
        };
        const user = await Auth.currentAuthenticatedUser();
        data.owner = user.username; // Set the owner's ID

        await API.graphql({
            query: createPerfiles,
            variables: { input: data },
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Adjust auth mode based on your setup
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        fetchProfiles();
        event.target.reset();
    }

    async function deletePerfil({ id }) {
        const newNotes = profiles.filter((note) => note.id !== id);
        setProfiles(newNotes);
        await API.graphql({
            query: deletePerfiles,
            variables: { input: { id } },
        });
    }
    const openPayment = (url) => {
        window.open(url)
    }
    async function fetchTrans() {
        console.log("HERE1-");
        let user = await Auth.currentAuthenticatedUser();
        console.log("USER DATA", user.username);
        const filter = {
            owner: {
                eq: user.username
            }
        };
        const apiData = await API.graphql({
            query: listTransacciones,
            variables: {
                filter: filter
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Adjust auth mode based on your setup
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        console.log("APIDATA", apiData);
        const transFromAPI = apiData.data.listTransacciones.items;
        /* setTrans(transFromAPI); */
    }


    return (
        <>
            <Offcanvas className="MenuSider" style={{ width: "50%" }} show={visible} onHide={handleClose} placement='end'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Perfiles</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Flex direction='column'>
                        <View as="form" margin="3rem 0" onSubmit={createPerfil}>
                            <Flex direction="row" justifyContent="center">
                                <TextField
                                    name="name"
                                    placeholder="Perfil Name"
                                    label="Perfil Name"
                                    labelHidden
                                    variation="quiet"
                                    required
                                />
                                <TextField
                                    name="description"
                                    placeholder="Perfil Description"
                                    label="Perfil Description"
                                    labelHidden
                                    variation="quiet"
                                    required
                                />
                                <Button type="submit" variation="primary">
                                    Crear Perfil
                                </Button>
                            </Flex>
                        </View>
                        <Heading level={2}>Tus Perfiles</Heading>
                        <View margin="3rem 0">
                            {profiles.map((profile) => (
                                <Flex
                                    key={profile.id || profile.name}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Text as="strong" fontWeight={700}>
                                        {profile.name}
                                    </Text>
                                    <Text as="span">{profile.description}</Text>
                                    <Button variation="link" onClick={() => deletePerfil(profile)} >
                                        Borrar Perfil
                                    </Button>
                                </Flex>
                            ))}
                        </View>
                      
                    </Flex>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}






export default ProfilesPage;