import React, { createContext, useContext, useState } from 'react';

const TramiteDataGeneral = createContext();

export function TramiteDataGeneralProvider({ children }) {
  const [data, setData] = useState({});
  const [calculo, setCalculo] = useState(null);
  const [declaracion, setDeclaracion] = useState(null);

  const updateData = (newData) => {
    setData(newData);
  };

  return (
    <TramiteDataGeneral.Provider value={{ data, updateData,calculo,setCalculo,declaracion,setDeclaracion }}>
      {children}
    </TramiteDataGeneral.Provider>
  );
}

// Custom hook to access the context
export function useTramiteDataGeneral() {
  return useContext(TramiteDataGeneral);
}