import React, { useState, useEffect } from "react";
import {
    Button,
    Divider,
    Flex,
    Heading,
    Text,
    TextField,
    View,
    withAuthenticator,
} from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";



export const Tramites = () => {
    const [trans, setTrans] = useState([]);

    const { user } = useAuthenticator((context) => [context.user]);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_TOKEN_VERIFICATION);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_FRESHSERVICE}/tickets?email=${user.attributes.email}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const DataResult = JSON.parse(result);
                setTrans(DataResult);
                /*                 console.log(DataResult);
                 */
            }
            )
            .catch(error => console.log('error', error));
    }, [])

    const fechaFormat = (fecha) => {
        var fechaIso = fecha;

        var newFecha = new Date(fechaIso);

        var formato = {
            year: 'numeric',
            month: "short",
            day: '2-digit',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            hour12: "true"
        };
        var fechaFromateada = newFecha.toLocaleString('es-ES', formato);

        return fechaFromateada;

    }
    return (
        <div style={{ width: "60%", textAlign: "center", margin: "auto" }}>
            <View className="FormTitle">Historial de pagos y trámites</View>
            <View margin="1rem 0">
                <ul style={{ margin: "2rem", padding: "0" }}>
                    {console.log(typeof (trans))}
                    {trans.length === undefined ? <>

                        <h1 style={{ color: "rgba(0,0,0,0.3)", textAlign: "center" }}>sin tramites</h1>
                    </>
                        :

                        trans.length === 0 || trans.tickets.length === 0 ?

                            <h1 style={{ color: "rgba(0,0,0,0.3)", textAlign: "center" }}>sin tramites</h1>

                            :
                            trans.tickets.map((tran) => (
                                <>
                                    {console.log(tran)}
                                    <li style={{ listStyle: "none" }} key={tran.id} id={tran.id}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ fontWeight: "bold", fontSize: "16px" }}>{tran.subject}</label>
                                            <label >${49}</label>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ textTransform: "uppercase", color: "rgba(136,136,136,1)", fontSize: "14px" }}>{fechaFormat(tran.created_at)}</label>
                                            <label style={{ textDecoration: "underline", color: "rgba(51,51,51,1)", textTransform: "uppercase", fontWeight: "bold" }}>{tran.status === 2 ? "Abierto" : tran.status === 3 ? "Pediente" : tran.status === 4 ? "Resuelto" : tran.status === 5 ? "Cerrado" : "Sin Estatus"}</label>
                                        </div>
                                        <div style={{ textAlign: "left" }}>
                                            <label style={{ fontSize: "13px" }}>Vigencia: {fechaFormat(tran.updated_at)}</label>
                                        </div>
                                        <div style={{ textAlign: "left" }}><a style={{ fontWeight: "bold", textDecoration: "underline", color: "black" }} href={`${process.env.REACT_APP_URL_FRESHSERVICE_TICKETS}/support/tickets/${tran.id}`}>Ver mas</a></div>
                                    </li >
                                    {/*  <Flex
                                    key={tran.id || tran.name}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    >
                                    <Text  as="strong" fontWeight={700}>
                                    {tran.descripcion}
                                    </Text>
                                    <Text  as="strong" fontWeight={700} textAlign={'left'}>
                                    {tran.amount}
                                    </Text>
                                    <Text as="strong" fontWeight={700}>
                                    {tran.status}
                                    </Text>
                                    <Text onClick={() => openPayment(tran.paymenturl)} as="strong" fontWeight={700}>
                                    {tran.status === 'Procesando' ? 'Link de pago' : 'Link de recibo'}
                                    </Text>
                                    <Text as="strong" fontWeight={700}>
                                    Detalles
                                    </Text>
                                </Flex> */}
                                    <Divider className='mb-3' />
                                </>
                            ))

                    }


                </ul>
            </View>
        </div>
    )
}
