import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@flipbyte/formik-json';
import { Field, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { listFormularios } from '../graphql/queries';
import { View } from '@aws-amplify/ui-react';
import '../App.css'
const DynamicFormViewer = () => {

    const { id } = useParams();
    const [formData, setFormData] = useState({})
    const save = (values, formikProps) => {
        console.log(values);
        alert(JSON.stringify(values));
        formikProps.setSubmitting(false);
    }
    useEffect(() => {
        getFormData();
    }, [])

    async function getFormData() {
        //get data from id
        let user = await Auth.currentAuthenticatedUser();
        console.log("USER DATA", user.username);
        const filter = {
            tramite: {
                eq: id
            }
        };
        const apiData = await API.graphql({
            query: listFormularios,
            variables: {
                filter: filter
            },
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken
            }
        });
        const forms = apiData.data.listFormularios.items;
        console.log("FORMS data", forms);

        if (forms.length !== 0) {
            forms[0].campos = JSON.parse(forms[0].campos)
            if (forms[0].tramite.localeCompare("PDCIE-Impuesto-emision-gases") === 0) {
                forms[0].campos.schema.elements.div1.elements.buttonV.onClick = new Function(forms[0].campos.schema.elements.div1.elements.buttonV.onClick)
                forms[0].campos.schema.elements.divForm.elements.buttonR.onClick = new Function(forms[0].campos.schema.elements.divForm.elements.buttonR.onClick)
            } else {
                forms[0].campos.schema.elements.buttonR.onClick = new Function(forms[0].campos.schema.elements.buttonR.onClick)
            }
            setFormData(forms[0])
            console.log(forms[0]);
        }
    }

    const [elementValues, setElementValues] = useState({});
    

    const hiddenInput = document.querySelector('input[name="Month"]');
    //const hiddenInput = document.querySelectorAll('select')
    console.log("HIDDEN---------------",hiddenInput);
    if(hiddenInput){
        console.log("ASSIGNES------",hiddenInput.value);
        hiddenInput.addEventListener('input', function () {
            // When the input value changes, you can access the new value
            const newValue = hiddenInput.value;
            console.log('Hidden input value changed:', newValue); 
        });
    } 

    useEffect(() => {
        const hiddenInput = document.querySelector('input[name="Month"]');
        //console.log("HIDDEN---------------",hiddenInput);
    
        if (hiddenInput) {
          // Add an event listener to detect changes
          //hiddenInput.addEventListener('change', handleHiddenInputChange);
        }
    
        return () => {
          // Clean up the event listener when the component unmounts
          if (hiddenInput) {
            //hiddenInput.removeEventListener('change', handleHiddenInputChange);
          }
        };
      }, [formData]);

      const handleHiddenInputChange = (event) => {
        const newValue = event.target.value;
        // Do something with the new value
        console.log('Hidden input value changed:', newValue);
      };
    

  useEffect(() => {
    console.log("SELECtor");
    const elements = document.querySelectorAll('input');

    elements.forEach((elem) => {
      const elementName = elem.name;

      // Save the initial value of the element
      /*setElementValues((prevValues) => ({
        ...prevValues,
        [elementName]: elem.value,
      }));*/

      // Attach event listeners to detect changes
      elem.addEventListener('propertychange', handleChange);
      elem.addEventListener('change', handleChange);
      elem.addEventListener('click', handleChange);
      elem.addEventListener('keyup', handleChange);
      elem.addEventListener('input', handleChange);
      elem.addEventListener('paste', handleChange);
    });

    return () => {
      // Clean up event listeners when the component unmounts
      elements.forEach((elem) => {
        elem.removeEventListener('propertychange', handleChange);
        elem.removeEventListener('change', handleChange);
        elem.removeEventListener('click', handleChange);
        elem.removeEventListener('keyup', handleChange);
        elem.removeEventListener('input', handleChange);
        elem.removeEventListener('paste', handleChange);
      });
    };
  }, [formData]);

  const handleChange = (event) => {
    console.log("DRAMATIX--------",event, event.srcElement.value, event.srcElement);
    const elem = event.target;
    const elementName = elem.name;
    console.log("Assignations", elem, elementName, elem.value);
    // If the value has changed...
    if (elementValues[elementName] !== elem.value) {
      // Update stored value
      /*setElementValues((prevValues) => ({
        ...prevValues,
        [elementName]: elem.value,
      }));*/

      // Do your action here
      // ...
    }
  };

    const validar = (values, formikProps, event) => {

        /*  fetch(`https://test-api-durango-digital.durango.gob.mx/v1/padrones/gases/${rfc}`)
             .then(response => {
                 if (!response.ok) {
                     throw new Error(`Error de red - Código de estado: ${response.status}`);
                 }
                 return response.json();
             })
             .then(data => {  console.log(data); })
             .catch(error => { console.error('Error:', error); }) */
        console.log(values);
        alert(JSON.stringify(values));
        formikProps.setSubmitting(false);
    }

    const formRef = useRef(null);
    return (
        <>
        <div className='HeaderTramite'>
            {formData.campos ? <View className='FormTitle'>{formData?.campos?.schema?.label}</View> : null}
            {formData.campos ? <View className='FormSubTitle'>{formData?.campos?.schema?.subTitle}</View> : null}
            {formData.campos ? <View className='FormDescripcion'>{formData?.campos?.schema?.descripcion}</View> : null}
        </div>
        <View className='ViewPrincipal'>

                {formData.campos ?
                    <>
                        <View className='FormContent'>
                            <Form
                                schema={formData.campos.schema}
                                onSubmit={save}
                                ref={formRef}
                                >

                            </Form>
                        </View>
                      
                    </>
                    : null}

        </View>
                    </>
    );
};

export default DynamicFormViewer;
