import React, { useState } from 'react'
import { Row, Col, Form, Button } from "react-bootstrap"
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { View } from '@aws-amplify/ui-react';

export const FormAcreditacionPersonalidad = () => {

    const validationSchema = yup.object().shape({
        razonSocial: yup.string().required("Este campo es obligatorio"),
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        CURP : yup.string().required("Este campo es obligatorio"),
        telefono : yup.number().required("Este campo es obligatorio"),
        constanciaSF: yup.mixed().required("Este campo es obligatorio"),
        escrituraConstitutiva: yup.mixed().required("Este campo es obligatorio"),
        poderRepresentante: yup.mixed().required("Este campo es obligatorio"),
        identificacionRepresentante: yup.mixed().required("Este campo es obligatorio"),
        cartaPoderTramitador: yup.mixed().required("Este campo es obligatorio"),
        identificacionTramitador: yup.mixed().required("Este campo es obligatorio"),
        estado: yup.string().required("Este campo es obligatorio"),
        municipio: yup.string().required("Este campo es obligatorio"),
        codigoPostal: yup.string().required("Este campo es obligatorio"),
        localidad: yup.string().required("Este campo es obligatorio"),
        colonia: yup.string().required("Este campo es obligatorio"),
        calle: yup.string().required("Este campo es obligatorio"),
        numExterior: yup.string().required("Este campo es obligatorio"),
        numInterior: yup.string().required("Este campo es obligatorio"),
        comprobanteDom: yup.mixed().required("Este campo es obligatorio"),
      })
      const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            console.log(values);
        },
        initialValues: {
            razonSocial: "",
            RFC: "",
            CURP: "",
            telefono: "",
            constanciaSF: "",
            escrituraConstitutiva: "",
            poderRepresentante: "",
            identificacionRepresentante: "",
            cartaPoderTramitador: "",
            identificacionTramitador: "",
            estado: "",
            municipio: "",
            codigoPostal: "",
            localidad: "",
            colonia: "",
            calle: "",
            numExterior: "",
            numInterior: "",
            comprobanteDom:""
        }
    });

    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Alta de Flotillas Foráneas</View>
                <View className='FormSubTitle'>Acreditación de Personalidad</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                        <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>Datos personales de Persona Moral</Form.Label>
                        </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Razón Social*</Form.Label>
                                <Form.Control 
                                type='text'
                                name="razonSocial"
                                value={formik.values.razonSocial}
                                onChange={formik.handleChange}
                                isValid={formik.touched.razonSocial && !formik.errors.razonSocial}
                                isInvalid={formik.touched.razonSocial && !!formik.errors.razonSocial}
                                
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.razonSocial}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>RFC*</Form.Label>
                                <Form.Control 
                                type='text'
                                name='RFC'
                                value={formik.values.RFC}
                                onChange={formik.handleChange}
                                isValid={formik.touched.RFC && !formik.errors.RFC}
                                isInvalid={formik.touched.RFC && !!formik.errors.RFC}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.RFC}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>CURP*</Form.Label>
                                <Form.Control 
                                type='text'
                                name='CURP'
                                value={formik.values.CURP}
                                onChange={formik.handleChange}
                                isValid={formik.touched.CURP && !formik.errors.CURP}
                                isInvalid={formik.touched.CURP && !!formik.errors.CURP}

                                />

                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.CURP}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Telefono*</Form.Label>
                                <Form.Control 
                                type='number'
                                name='telefono'
                                value={formik.values.telefono}
                                onChange={formik.handleChange}
                                isValid={formik.touched.telefono && !formik.errors.telefono}
                                isInvalid={formik.touched.telefono && !!formik.errors.telefono}

                                />

                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.telefono}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Constancia de Situación Fiscal*</Form.Label>
                                <Form.Control 
                                type='file'
                                name='constanciaSF'
                                value={formik.values.constanciaSF}
                                onChange={formik.handleChange}
                                isValid={formik.touched.constanciaSF && !formik.errors.constanciaSF}
                                isInvalid={formik.touched.constanciaSF && !!formik.errors.constanciaSF}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.constanciaSF}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Escritura Constitutiva*</Form.Label>
                                <Form.Control 
                                type='file'
                                name='escrituraConstitutiva'
                                value={formik.values.escrituraConstitutiva}
                                onChange={formik.handleChange}
                                isValid={formik.touched.escrituraConstitutiva && !formik.errors.escrituraConstitutiva}
                                isInvalid={formik.touched.escrituraConstitutiva && !!formik.errors.escrituraConstitutiva}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.escrituraConstitutiva}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Poder del Representante Legal</Form.Label>
                                <Form.Control 
                                type='file'
                                name='poderRepresentante'
                                value={formik.values.poderRepresentante}
                                onChange={formik.handleChange}
                                isValid={formik.touched.poderRepresentante && !formik.errors.poderRepresentante}
                                isInvalid={formik.touched.poderRepresentante && !!formik.errors.poderRepresentante}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.poderRepresentante}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Identificación del Representante Legal</Form.Label>
                                <Form.Control 
                                type='file'
                                name='identificacionRepresentante'
                                value={formik.values.identificacionRepresentante}
                                onChange={formik.handleChange}
                                isValid={formik.touched.identificacionRepresentante && !formik.errors.identificacionRepresentante}
                                isInvalid={formik.touched.identificacionRepresentante && !!formik.errors.identificacionRepresentante}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.identificacionRepresentante}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Carta Poder Simple del Tramitador</Form.Label>
                                <Form.Control 
                                type='file'
                                name='cartaPoderTramitador'
                                value={formik.values.cartaPoderTramitador}
                                onChange={formik.handleChange}
                                isValid={formik.touched.cartaPoderTramitador && !formik.errors.cartaPoderTramitador}
                                isInvalid={formik.touched.cartaPoderTramitador && !!formik.errors.cartaPoderTramitador}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.cartaPoderTramitador}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Identificación Oficial del Tramitador</Form.Label>
                                <Form.Control 
                                type='file'
                                name='identificacionTramitador'
                                value={formik.values.identificacionTramitador}
                                onChange={formik.handleChange}
                                isValid={formik.touched.identificacionTramitador && !formik.errors.identificacionTramitador}
                                isInvalid={formik.touched.identificacionTramitador && !!formik.errors.identificacionTramitador}
                                />
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.identificacionTramitador}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Label>Datos de domicilio de Persona Moral</Form.Label>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Estado</Form.Label>
                            <Form.Control 
                            type='text'
                            name='estado'
                            value={formik.values.estado}
                            onChange={formik.handleChange}
                            isValid={formik.touched.estado && !formik.errors.estado}
                            isInvalid={formik.touched.estado && !!formik.errors.estado}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.estado}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Municipio</Form.Label>
                            <Form.Control 
                            type='text'
                            name='municipio'
                            value={formik.values.municipio}
                            onChange={formik.handleChange}
                            isValid={formik.touched.municipio && !formik.errors.municipio}
                            isInvalid={formik.touched.municipio && !!formik.errors.municipio}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.municipio}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Código Postal*</Form.Label>
                            <Form.Control 
                            type='text'
                            name='codigoPostal'
                            value={formik.values.codigoPostal}
                            onChange={formik.handleChange}
                            isValid={formik.touched.codigoPostal && !formik.errors.codigoPostal}
                            isInvalid={formik.touched.codigoPostal && !!formik.errors.codigoPostal}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.codigoPostal}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Localidad*</Form.Label>
                            <Form.Control 
                            type='text'
                            name='localidad'
                            value={formik.values.localidad}
                            onChange={formik.handleChange}
                            isValid={formik.touched.localidad && !formik.errors.localidad}
                            isInvalid={formik.touched.localidad && !!formik.errors.localidad}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.localidad}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Colonia*</Form.Label>
                            <Form.Control 
                            type='text'
                            name='colonia'
                            value={formik.values.colonia}
                            onChange={formik.handleChange}
                            isValid={formik.touched.colonia && !formik.errors.colonia}
                            isInvalid={formik.touched.colonia && !!formik.errors.colonia}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.colonia}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Calle*</Form.Label>
                            <Form.Control 
                            type='text'
                            name='calle'
                            value={formik.values.calle}
                            onChange={formik.handleChange}
                            isValid={formik.touched.calle && !formik.errors.calle}
                            isInvalid={formik.touched.calle && !!formik.errors.calle}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.calle}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Número Exterior*</Form.Label>
                            <Form.Control 
                            type='number'
                            name='numExterior'
                            value={formik.values.numExterior}
                            onChange={formik.handleChange}
                            isValid={formik.touched.numExterior && !formik.errors.numExterior}
                            isInvalid={formik.touched.numExterior && !!formik.errors.numExterior}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.numExterior}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} className='position-relative'>
                            <Form.Label>Número Interior*</Form.Label>
                            <Form.Control 
                            type='number'
                            name='numInterior'
                            value={formik.values.numInterior}
                            onChange={formik.handleChange}
                            isValid={formik.touched.numInterior && !formik.errors.numInterior}
                            isInvalid={formik.touched.numInterior && !!formik.errors.numInterior}
                            
                            />
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.numInterior}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Comprobante de domicilio</Form.Label>
                                <Form.Control 
                                type='file'
                                name='comprobanteDom'
                                value={formik.values.comprobanteDom}
                                onChange={formik.handleChange}
                                isValid={formik.touched.comprobanteDom && !formik.errors.comprobanteDom}
                                isInvalid={formik.touched.comprobanteDom && !!formik.errors.comprobanteDom}
                                />
                            </Form.Group>
                            <Form.Control.Feedback type='invalid' tooltip>{formik.errors.comprobanteDom}</Form.Control.Feedback>

                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='d-grid gap-2'>
                                <Button type='submit' variant='secondary'>Siguiente</Button>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='d-grid gap-2'>
                                <Button type='submit' variant='link'>Regresar</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </View>
            </View>
        </>
    )
}
