import React, { useRef } from 'react';
import '../../App.css'
import { useTramiteDataGeneral } from '../../TramiteContext'
import { Button as ButtonB } from 'react-bootstrap';
import { Row } from 'react-bootstrap'


const ResumenCard = ({calculoImp}) => {
    const { data, calculo } = useTramiteDataGeneral()
    return (
        <aside >
                    <Row >
                        <ButtonB style={{ width: "50%", margin: "auto" }} variant='success' onClick={() => calculoImp()}>Calcular Impuesto</ButtonB>
                    </Row>
                    <div className="seccion1">
                        <span>Resumen declaración</span>
                        <label>Verifica que la declaración sea correcta</label>
                    </div>
                    {data.infoRFC && (
                        <div className='personalInfoseccion'>
                            <span>{data.infoRFC.nombre}</span>
                            <span>RFC: {data.infoRFC.rfc}</span>
                            <span>Padron: {data.infoRFC.padron.nombre}</span>
                            <span>Periodo: {data.infoRFC.periodicidad.tipoPeriodo}</span>
                        </div>
                    )}
                    <div className="seccion2">
                        {/*}  <label style={{display:"flex", justifyContent:"space-between"}}>Base Gravable <strong>$0.00</strong></label>
                        <ul style={{listStyle:"none"}}>
                            <li style={{display:"flex", justifyContent:"space-between"}}>Suelo y SubSuelo <strong>$0.00</strong></li>
                            <li style={{display:"flex", justifyContent:"space-between"}}>Agua <strong>$0.00</strong></li>
                        </ul> */}
                        {
                            calculo && (
                                <div>
                                    <span>lote:{calculo.estadoCuenta.loteId}-tramite:{calculo.estadoCuenta.tramiteId}</span>
                                    <span>No. De Toneladas de Residuos <strong>$0.00</strong></span>
                                    <span>Impuesto Determinado <strong>$0.00</strong></span>
                                    <span>Fomento educación <strong>$0.00</strong></span>
                                    <span>Actualización <strong>$0.00</strong></span>
                                    <span>Recargos <strong>{calculo.calculos.recargos}</strong></span>
                                    <span>subTotal 1 <strong>${calculo.calculos.subtotal1}</strong></span>
                                    <span>Compensación <strong>$0.00</strong></span>
                                    <span>SubTotal 2 <strong>${calculo.calculos.subtotal2}</strong></span>
                                    <span>Multas <strong>${calculo.calculos.multas}</strong></span>
                                    <span>Saldo a favor <strong>$0.00</strong></span>
                                    <span>Total <strong>${calculo.calculos.total}</strong></span>
                                    <div>
                                        Conceptos
                                        {calculo.conceptos.map(cp => {
                                            return (<div>
                                                {cp.clave}-{cp.concepto}-importe:{cp.importe}
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            )
                        }
                        {/*    <span>PAGO 1 <strong> $104.75</strong></span>
                    <span>PAGO 2 <strong> $41.75</strong></span>
                    <span>PAGO 3 <strong> $0.75</strong></span>
                    <span style={{ fontWeight: "bold" }}>TOTAL A PAGAR <strong> $147.25</strong></span> */}
                    </div>
                </aside>
    );
};

export default ResumenCard;