import React from 'react';
import logoDurango from "../assets/DurGobEst.png"
import instagram from "../assets/instagram.png"
import facebook from "../assets/facebook.png"
import '../styles/Footer.css'
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content" >
                <div className="footer-left">
                    <img src={logoDurango} alt="logo-Durango" className="footer-logo" />
                </div>
                <div className="footer-right">

                    <a href="https://www.facebook.com/gobdgo" className="social-icon" target='_blank' rel="noreferrer"><img src={facebook} alt="Durango" /></a>
                    <a href='https://twitter.com/gobdgo?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target='_blank' rel="noreferrer">
                        <svg width="25" height="24" viewBox="0 0 135 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M119.705 16.4535L128.415 19.9273L78.8093 72.7207L70.1546 69.3925L119.705 16.4535Z" fill="#52545B" />
                            <path d="M53.8721 87.0503L62.7688 90.324L9.18675 147.382L0.341579 144.258L53.8721 87.0503Z" fill="#52545B" />
                            <path d="M96.6572 136.355L14.4082 25.9433L37.9074 26.061L120.106 136.355L96.6572 136.355Z" stroke="#52545B" strokeWidth="13" />
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/gobdgo/" className="social-icon" target='_blank' rel="noreferrer"><img src={instagram} alt="Durango" /></a>
                </div>
            </div>
            <hr className="footer-divider" />
            <div className="footer-bottom">
                <span className="footer-text-left" >©2023 Gobierno del Estado de Durango</span>
                <div className='divRigth'>

                    <a className="footer-text-right" href="https://storage.googleapis.com/durango-digital-static-files/AVISO%20DE%20PRIVACIDAD-DURANGO%20DIGITAL_2022.pdf"  target='_blank' rel="noopener noreferrer" >Avisos de Privacidad</a>
                    <a className="footer-text-right" href="https://storage.googleapis.com/durango-digital-static-files/T%C3%A9rminos%20y%20condiciones%20de%20Plataforma%20Durango%20Digital_2022.pdf" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                </div>

            </div>
        </footer>
    );
}



export default Footer;
