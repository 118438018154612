import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TablePagination from '@mui/material/TablePagination';
import { Button, Form, Row as RowB, Col } from 'react-bootstrap';
import { View } from '@aws-amplify/ui-react';
import { useFormik } from 'formik';
import * as yup from 'yup';

function createData(numero) {
    return {
        numero
    };
}

function Row(props) {
    const { row, data, setData } = props;
    const initialValues = {};
    const validationSchema = {};
    validationSchema[`NoSerie-${row.numero}`] = yup.string().required("Este campo es obligatorio");
    validationSchema[`Modelo-${row.numero}`] = yup.string().required("Este campo es obligatorio");
    validationSchema[`EstadoPro-${row.numero}`] = yup.string().required("Este campo es obligatorio");
    validationSchema[`Factura-${row.numero}`] = yup.mixed().required("Este campo es obligatorio");

    initialValues[`NoSerie-${row.numero}`] = "";
    initialValues[`Modelo-${row.numero}`] = "";
    initialValues[`EstadoPro-${row.numero}`] = "";
    initialValues[`Factura-${row.numero}`] = null;


   
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object().shape(validationSchema),
        onSubmit: values => {
            console.log(values);
            const newVehiculo = [...data, values];
            setData(newVehiculo);
        },

    });
    return (
        <TableRow onSubmit={formik.handleSubmit}>

            <TableCell>

                <Form.Group as={Col}>
                    <Form.Label>{row.numero}</Form.Label>
                </Form.Group>
            </TableCell>
            <TableCell>

                <Form.Group as={Col} controlId={`Formik-NoSerie-${row.numero}`}>
                    <Form.Control
                        type='text'
                        autoComplete='off'
                        name={`NoSerie-${row.numero}`}
                        placeholder='No. Serie'
                        value={formik.values[`NoSerie-${row.numero}`] ?? ""}
                        onChange={formik.handleChange}
                        isValid={formik.touched[`NoSerie-${row.numero}`] && !formik.errors[`NoSerie-${row.numero}`]}
                        isInvalid={formik.touched[`NoSerie-${row.numero}`] && !!formik.errors[`NoSerie-${row.numero}`]}
                        />
                </Form.Group>

            </TableCell>
            <TableCell>
                <Form.Group as={Col} controlId={`Formik-Modelo-${row.numero}`}>
                    <Form.Control
                        type='text'
                        name={`Modelo-${row.numero}`}
                        placeholder='Modelo'
                        autoComplete='off'
                        value={formik.values[`Modelo-${row.numero}`] ?? ""}
                        onChange={formik.handleChange}
                        isValid={formik.touched[`Modelo-${row.numero}`] && !formik.errors[`Modelo-${row.numero}`]}
                        isInvalid={formik.touched[`Modelo-${row.numero}`] && !!formik.errors[`Modelo-${row.numero}`]}
                        />
                </Form.Group>

            </TableCell>
            <TableCell>
                <Form.Group as={Col} controlId={`Formik-EstadoPro-${row.numero}`}>

                    <Form.Control
                        type='text'
                        placeholder='Estado Procedente'
                        name={`EstadoPro-${row.numero}`}
                        value={formik.values[`EstadoPro-${row.numero}`] ?? "" }
                        onChange={formik.handleChange}
                        autoComplete='off'
                        isValid={formik.touched[`EstadoPro-${row.numero}`] && !formik.errors[`EstadoPro-${row.numero}`]}
                        isInvalid={formik.touched[`EstadoPro-${row.numero}`] && !!formik.errors[`EstadoPro-${row.numero}`]}
                        />
                </Form.Group>

            </TableCell>
            <TableCell>
                <Form.Group as={Col} controlId={`Formik-Factura-${row.numero}`}>
                    <Form.Control
                        type='file'
                        placeholder='Factura'
                        name={`Factura-${row.numero}`}
                        value={formik.values[`Factura-${row.numero}`] ?? "" }
                        autoComplete='off'
                        onChange={formik.handleChange}
                        isValid={formik.touched[`Factura-${row.numero}`] && !formik.errors[`Factura-${row.numero}`]}
                        isInvalid={formik.touched[`Factura-${row.numero}`] && !!formik.errors[`Factura-${row.numero}`]}
                        />
                </Form.Group>

            </TableCell>
            <TableCell>
                <Form.Group as={Col}>
                    <Button variant='secondary' onClick={()=> formik.handleSubmit()} >Revisar</Button>
                </Form.Group>
            </TableCell>
            <TableCell style={{ display: "flex" }}>


                <IconButton>
                    <EditIcon />
                </IconButton>
                <IconButton>
                    <DeleteIcon />
                </IconButton>


            </TableCell>

        </TableRow>
    );
}



const rows = [];

for (let i = 0; i < 500; i++) {
    var nuevoUsuario = createData(i + 1);
    rows.push(nuevoUsuario);
}


export const FormCapturaVehiculos = () => {
    const [data, setData]= React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleEnviar =(datos)=>{
        console.log(datos)
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Alta de Flotillas Foráneas</View>
                <View className='FormSubTitle'>Captura de Flotilla</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>
            <View className="ViewPrincipal">
                <View className="ViewContent">
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >No.</TableCell>
                                        <TableCell>Número de Serie</TableCell>
                                        <TableCell align="center">Modelo</TableCell>
                                        <TableCell align="center">Estado Procedente</TableCell>
                                        <TableCell align="center">Factura</TableCell>
                                        <TableCell />
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <Row key={index} row={row} setData={setData} data={data}/>
                                    ))}

                                </TableBody>
                            </Table>
                            <Button onClick={()=> handleEnviar(data)}>Enviar</Button>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>

                </View>
            </View>
        </>
    )

}
