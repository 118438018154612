
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import '../App.css';

function FormPagos(props) {
  const {metodoPago, setMetodoPago} = props;
  const [value, setValue] = React.useState(metodoPago);

  const handleChange = (event) => {
    setValue(event.target.value);
    setMetodoPago(event.target.value)
    console.log(event.target.value)
  };


  return (
    <>

      <FormControl className='FormPago' style={{ backgroundColor: "#D4E0E0", width: "100%", borderRadius: "10px", padding: "10px", marginTop: "10px" }}>
        <FormLabel style={{display:"flex", justifyContent:"flex-start", textTransform:"uppercase", margin:"10px", color:"black"}} id="demo-controlled-radio-buttons-group" >Selecciona un método de pago</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
            <div style={{ backgroundColor: "white", margin: "5px", borderRadius: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <label style={{display:"grid", alignContent:"center"}}>
                  Tarjeta de Crédito/Débito

                </label>
                <div style={{display:"flex"}}>
                <div style={{margin:"5px"}}>

                  <svg width="55" height="19" viewBox="0 0 55 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.3315 0.85175L23.7115 17.7739H19.3341L22.9547 0.85175H27.3315ZM45.749 11.7784L48.0537 5.42359L49.3798 11.7784H45.749ZM50.6331 17.7739H54.6822L51.1489 0.85175H47.4115C46.5717 0.85175 45.8631 1.34021 45.5476 2.09326L38.9802 17.7739H43.5772L44.4898 15.2469H50.1061L50.6331 17.7739ZM39.2084 12.2487C39.2271 7.78235 33.0319 7.53651 33.0747 5.54142C33.0876 4.93352 33.6666 4.28866 34.9311 4.12316C35.5593 4.04122 37.2866 3.97855 39.248 4.88103L40.016 1.29254C38.9625 0.91013 37.6069 0.542175 35.9209 0.542175C31.5943 0.542175 28.549 2.84256 28.5232 6.13647C28.4959 8.57235 30.6972 9.93169 32.356 10.7415C34.0613 11.5711 34.6344 12.1025 34.6269 12.8443C34.6156 13.9803 33.2665 14.4805 32.0078 14.5003C29.8065 14.5351 28.5291 13.9063 27.511 13.4318L26.7178 17.1397C27.7402 17.6094 29.6282 18.0176 31.5868 18.0385C36.1849 18.0385 39.1934 15.7665 39.2084 12.2487ZM21.0763 0.85175L13.984 17.7739H9.35593L5.86599 4.26885C5.6539 3.43707 5.46965 3.13285 4.82533 2.78203C3.77396 2.21163 2.03596 1.67549 0.506836 1.34343L0.611277 0.85175H8.05979C9.00886 0.85175 9.86313 1.48375 10.0784 2.5769L11.922 12.3697L16.4772 0.85175H21.0763Z" fill="#1434CB" />
                  </svg>
                </div>
                <div style={{margin:"5px"}}>

                  <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27.3811 3.7324H15.9539V24.2675H27.3811V3.7324Z" fill="#FF5F00" />
                    <path d="M16.6795 13.9999C16.6777 12.0222 17.1259 10.0701 17.9901 8.2912C18.8543 6.51233 20.1118 4.95339 21.6676 3.73239C19.741 2.21805 17.4272 1.27631 14.9907 1.0148C12.5543 0.753293 10.0933 1.18255 7.88929 2.25354C5.68523 3.32453 3.82696 4.99403 2.52687 7.07121C1.22679 9.14839 0.537354 11.5494 0.537354 13.9999C0.537354 16.4504 1.22679 18.8514 2.52687 20.9286C3.82696 23.0058 5.68523 24.6753 7.88929 25.7463C10.0933 26.8173 12.5543 27.2465 14.9907 26.985C17.4272 26.7235 19.741 25.7818 21.6676 24.2674C20.1118 23.0464 18.8543 21.4875 17.9901 19.7086C17.1259 17.9298 16.6777 15.9776 16.6795 13.9999Z" fill="#EB001B" />
                    <path d="M42.7971 13.9999C42.7972 16.4504 42.1079 18.8514 40.8079 20.9286C39.5078 23.0058 37.6496 24.6753 35.4456 25.7463C33.2416 26.8173 30.7807 27.2465 28.3443 26.985C25.9078 26.7235 23.5941 25.7818 21.6676 24.2674C23.222 23.0452 24.4785 21.486 25.3426 19.7074C26.2066 17.9288 26.6556 15.9773 26.6556 13.9999C26.6556 12.0226 26.2066 10.071 25.3426 8.29241C24.4785 6.51382 23.222 4.95462 21.6676 3.73239C23.5941 2.21805 25.9078 1.27631 28.3443 1.0148C30.7807 0.753293 33.2416 1.18257 35.4456 2.25357C37.6496 3.32457 39.5078 4.99407 40.8079 7.07125C42.1079 9.14843 42.7972 11.5495 42.7971 13.9999Z" fill="#F79E1B" />
                    <path d="M41.5512 22.0925V21.6721H41.7207V21.5864H41.289V21.6721H41.4586V22.0925H41.5512ZM42.3894 22.0925V21.5856H42.257L42.1048 21.9342L41.9526 21.5856H41.8201V22.0925H41.9136V21.7101L42.0563 22.0398H42.1532L42.296 21.7093V22.0925H42.3894Z" fill="#F79E1B" />
                  </svg>
                </div>

          <FormControlLabel value="pago1"  style={{ width: "20px", heidth: "20px" }} control={<Radio />}  />
                </div>

              </div>

              <div style={{height:"58px", backgroundColor: "white", margin: "5px", borderRadius: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <label style={{display:"grid", alignContent:"center"}}>
                  Transferencia SPEI
                </label>
          <FormControlLabel value="pago2"  style={{ width: "20px", heidth: "20px" }} control={<Radio />}  />
              </div>

              <div style={{ height:"58px",backgroundColor: "white", margin: "5px", borderRadius: "10px", display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <label style={{display:"grid", alignContent:"center"}}>
                  Pago Referenciado
                </label>
          <FormControlLabel value="pago3" control={<Radio />}  style={{ width: "20px", heidth: "20px" }} />
              </div>
        </RadioGroup>
      </FormControl>

    </>
  );
}

export default FormPagos;