/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFormularios = /* GraphQL */ `
  query GetFormularios($id: ID!) {
    getFormularios(id: $id) {
      id
      dependencia
      tramite
      api
      campos
      metodo
      createdAt
      updatedAt
    }
  }
`;
export const listFormularios = /* GraphQL */ `
  query ListFormularios(
    $filter: ModelFormulariosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormularios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dependencia
        tramite
        api
        campos
        metodo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerfiles = /* GraphQL */ `
  query GetPerfiles($id: ID!) {
    getPerfiles(id: $id) {
      id
      owner
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listPerfiles = /* GraphQL */ `
  query ListPerfiles(
    $filter: ModelPerfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransacciones = /* GraphQL */ `
  query GetTransacciones($id: ID!) {
    getTransacciones(id: $id) {
      id
      owner
      tramite
      amount
      status
      paymenturl
      createdAt
      updatedAt
    }
  }
`;
export const listTransacciones = /* GraphQL */ `
  query ListTransacciones(
    $filter: ModelTransaccionesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransacciones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        tramite
        amount
        status
        paymenturl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUrl = /* GraphQL */ `
  query GetUrl($query: QueryGetUrlQueryInput, $body: QueryGetUrlBodyInput) {
    getUrl(query: $query, body: $body) {
      statusCode
      body
    }
  }
`;
