import { createBrowserRouter } from "react-router-dom";
import UserLayout from "./components/UserLayout";
import TramiteLayout from './components/TramiteLayout'
import TransPage from "./components/Transactions";
import { CreateForm } from "./views/CreateForm";
import DynamicFormViewer from "./views/DynamicFormViewer";
import FreshServiceTicket from "./views/FreshServiceTicket";
import { Tramites } from "./views/Tramites";
import FormGases from "./components/Static_Forms/FormGases";
import { FormEmisionCon } from "./components/Static_Forms/FormEmisionCon";
import { FormDepositoResiduos } from "./components/Static_Forms/FormDepositoResiduos";
import { ConfigCuenta } from "./views/ConfigCuenta";
import { Creditos } from "./views/Creditos";
import { FormAutosNuevos } from "./components/Static_Forms/FormAutosNuevos";
import { FormExtraccionMateriales } from "./components/Static_Forms/FormExtraccionMateriales";
import { FormInscImpuestoNomina } from "./components/Static_Forms/FormInscImpuestoNomina";
import { useRef } from "react";
import { FormRentaInmuebles } from "./components/Static_Forms/FormRentaInmuebles";
import { FormBebidasAlcoholicas } from "./components/Static_Forms/FormBebidasAlcoholicas";
import { FormImpuestoHospedaje } from "./components/Static_Forms/FormImpuestoHospedaje";
import { FormLicenciaAuto } from "./components/Static_Forms/FormLicenciaAuto";
import { FormLicenciaMotocicleta } from "./components/Static_Forms/FormLicenciaMotociclista";
import { FormActaNacimiento } from "./components/Static_Forms/FormActaNacimiento";
import { FormActaMatrimonio } from "./components/Static_Forms/FormActaMatrimonio";
import { FormActaDefuncion } from "./components/Static_Forms/FormActaDefuncion";
import { FormFirmasAsentadasOtrosFuncionarios } from "./components/Static_Forms/FormFirmasAsentadasOtrosFuncionarios";
import { FormCorreccionActas } from "./components/Static_Forms/FormCorreccionActas";
import { FormAvaluoCatastral } from "./components/Static_Forms/FormAvaluoCatastral";
import { FormCertificadoLibertadExistenciaGravamen } from "./components/Static_Forms/FormCertificadoLibertadExistenciaGravamen";
import { FormaActaReconocimiento } from "./components/Static_Forms/FormaActaReconocimiento";
import { FormaActaDivorcio } from "./components/Static_Forms/FormaActaDivorcio";
import { FormaActaRegistroCivil } from "./components/Static_Forms/FormaActaRegistroCivil";
import { FormaRefrendo } from "./components/Static_Forms/FormaRefrendo";
import { FormaConstanciaPagoVehicular } from "./components/Static_Forms/FormaConstanciaPagoVehicular";
import { FormaReplaqueo } from "./components/Static_Forms/FormaReplaqueo";
import { FormAcreditacionPersonalidad } from "./components/Flotillas/FormAcreditacionPersonalidad";
import { FormCapturaFlotilla } from "./components/Flotillas/FormCapturaFlotilla";
import { FormCapturaVehiculos } from "./components/Flotillas/FormCapturaVehiculos";
import { HistoralFlotillas } from "./components/Flotillas/HistoralFlotillas";
import { DetallesFlotillas } from "./components/Flotillas/DetallesFlotillas";
import { HistorialPersonalidades } from "./components/Flotillas/HistorialPersonalidades";
import { FormContratoCompraventa } from "./components/Static_Forms/FormContratoCompraventa";
import { FormContratoCredito } from "./components/Static_Forms/FormContratoCredito";
import { FormBusquedaRegistroDatos } from "./components/Static_Forms/FormBusquedaRegistroDatos";
const router = createBrowserRouter([
    {
        path: "/",
        element: <UserLayout />,
        children: [
            {
                path: "/",
                element: <TransPage />
            },
            {
                path: "/CreateForm",
                element: <CreateForm />
            },
            {
                path: "/tramite-forms/:id",
                element: <DynamicFormViewer />
            },
            {
                path: "/tramite-service/DPC-Alta-vehicular",
                element: <FreshServiceTicket />
            },
            {
                path: "/tramites-servicios",
                element: <Tramites />
            },
            {
                path: "/Configuracion",
                element: <ConfigCuenta />
            },
            {
                path: "/creditos-y-convenios",
                element: <Creditos />
            }

        ]
    }, {
        path: "/tramite",
        element: <TramiteLayout />,
        children: [


            {
                path: "/tramite/:id",
                element: <DynamicFormViewer />
            },
            {
                path: "/tramite/PDCIE-Impuesto-emision-gases",
                element: <FormGases />
            },
            {
                path: "/tramite/PDCIE-PagoEmisionContaminantes",
                element: <FormEmisionCon />
            },
            {
                path: "/tramite/PDCIE-PagoImpuestoDepositoResiduos",
                element: <FormDepositoResiduos />
            },
            {
                path: "/tramite/PDCIE-ImpuestoAutosNuevos",
                element: <FormAutosNuevos />
            },
            {
                path: "/tramite/PDCIE-PagoImpuestoExtraccionMateriales",
                element: <FormExtraccionMateriales />
            },
            {
                path: "/tramite/PDCIE-PagoImpuestoRentaInmuebles",
                element: <FormRentaInmuebles />
            },
            {
                path: "/tramite/PDCIE-InsImpuestoNomina",
                element: <FormInscImpuestoNomina />
            },
            {
                path: "/tramite/PDCIE-PagoImpuestoBebidasAlcoholicas",
                element: <FormBebidasAlcoholicas />
            },
            {
                path: "/tramite/PDCIE-InscImpuestosHospedaje",
                element: <FormImpuestoHospedaje />
            },
            {
                path: "/tramite/DPC-LicenciaAutomovilista",
                element: <FormLicenciaAuto />
            },
            {
                path: "/tramite/DPC-LicenciaMotociclista",
                element: <FormLicenciaMotocicleta />
            },
            {
                path: "/tramite/DGRC-CopiaActaNacimiento",
                element: <FormActaNacimiento />
            },
            {
                path: "/tramite/DGRC-CopiaActaMatrimonio",
                element: <FormActaMatrimonio />
            },
            {
                path: "/tramite/DGRC-CopiaActaDefuncion",
                element: <FormActaDefuncion />
            },
            {
                path: "/tramite/SGG-LegalizacionFirmasOtrosFuncionarios",
                element: <FormFirmasAsentadasOtrosFuncionarios />
            },
            {
                path: "/tramite/DGRC-CorreccionActa",
                element: <FormCorreccionActas />
            },
            {
                path: "/tramite/DGC-AvaluoCatastral1a1875",
                element: <FormAvaluoCatastral />
            },
            {
                path: "/tramite/RPP-CertificadoLibreGravamen",
                element: <FormCertificadoLibertadExistenciaGravamen />
            },
            {
                path: "/tramite/DGRC-CopiaActaReconocimiento",
                element: <FormaActaReconocimiento />
            },
            {
                path: "/tramite/DGRC-CopiaActaDivorcio",
                element: <FormaActaDivorcio />
            },
            {
                path: "/tramite/DGRC-FormatoUnicoActa",
                element: <FormaActaRegistroCivil />
            },
            {
                path: "/tramite/DPC-Refrendo",
                element: <FormaRefrendo />
            },
            {
                path: "/tramite/DPC-ConstanciaVehicular",
                element: <FormaConstanciaPagoVehicular />
            },
            {
                path: "/tramite/Captura-vehiculos",
                element: <FormCapturaVehiculos />
            },
            {
                path:"/tramite/Personalidad",
                element: <FormAcreditacionPersonalidad/>
            },
            {
                path:"/tramite/Caprtura-Flotilla",
                element: <FormCapturaFlotilla/>
            },
            {
                path: "/tramite/Historial-Flotillas",
                element: <HistoralFlotillas />
            },
            {
                path: "/tramite/Detalle-Flotillas",
                element: <DetallesFlotillas />
            },
            {
                path: "/tramite/Historial-Personalidades",
                element: <HistorialPersonalidades />
            },
            {
                path: "/tramite/DPC-Replaqueo",
                element: <FormaReplaqueo />
            },
            {
                path: "/tramite/RPP-ContratoCompraventa",
                element: <FormContratoCompraventa />
            },
            {
                path: "/tramite/RPP-ContratoCredito",
                element: <FormContratoCredito />
            },
            {
                path: "/tramite/DGRC-BusquedaRegistroDatos",
                element: <FormBusquedaRegistroDatos />
            }
        ]
    }
])

export default router;