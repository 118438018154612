import { useState } from 'react'
import { Button, Form, Row, Col, InputGroup, Accordion } from 'react-bootstrap';
import { View } from '@aws-amplify/ui-react';
import "../../App.css";

export const HistorialPersonalidades = () => {
  return (
    <>
    <div className='HeaderTramite'>
        <View className='FormTitle'>Alta de Flotillas Foráneas</View>
        <View className='FormSubTitle'>Historial de Personalidades</View>
        <View className='FormDescripcionFlotillas'>
            <div className='InfoFlotillas'>
                <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Nombre, denominación o razón social:</strong> </span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Domicilio Fiscal: </strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>RFC:</strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Correo Electrónico:</strong></span>
                    <span style={{ display: "flex", justifyContent: "center" }}><strong>Teléfono:</strong></span>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                    <span style={{ display: "flex", justifyContent: "center" }}> Empresa Comercializadora Prueba S.A De C.V.</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>Torreón, Coahuila</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>XXX-XXXXXX-XXX</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>Prueba@empresa.com</span>
                    <span style={{ display: "flex", justifyContent: "center" }}>000-000-00-00</span>
                </div>
            </div>
        </View>
    </div>

    <View className="ViewPrincipal">
        <View className="ViewContent">
            <Row className='mb-3'>
                <Form.Group as={Col}>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Buscar"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                        />
                        <Button variant="secondary" id="button-addon2">
                            Buscar
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Row>

            <Accordion  alwaysOpen>
                <Accordion.Item eventKey="0" className='mb-3'>
                    <Accordion.Header style={{textDecoration:"underline"}}>Personalidad Número 1234</Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>RFC</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>CURP</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" style={{marginTop:"2rem"}}>
                                
                                <Button variant='secondary' > Ver Detalles</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='mb-3'>
                    <Accordion.Header style={{textDecoration:"underline"}}>Personalidad Número 322</Accordion.Header>
                    <Accordion.Body>
                    <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>RFC</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>CURP</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" style={{marginTop:"2rem"}}>
                                
                                <Button variant='secondary' > Ver Detalles</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='mb-3'> 
                    <Accordion.Header style={{textDecoration:"underline"}}>Personalidad Número 431</Accordion.Header>
                    <Accordion.Body>
                    <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>RFC</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>CURP</Form.Label>
                                <Form.Control
                                    type='text'
                                    value=""
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" style={{marginTop:"2rem"}}>
                                
                                <Button variant='secondary' > Ver Detalles</Button>
                            </Form.Group>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </View>
    </View>
</>

  )
}
