import React, { useState, useEffect } from "react";
import {
  View,
  
} from "@aws-amplify/ui-react";
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button } from "react-bootstrap";
import "../App.css";
export const Creditos = () => {
    const { Formik } = formik;
    const schema = yup.object().shape({
      buscar: yup.string().required("Este Campo es Obligatorio"),
    
  
    });
  
  return (
    <>
      <div className="FormTramite">
        <View className="FormTitle">Créditos y Convenios</View>
        <Formik
          validationSchema={schema}
          initialValues={{
            buscar: "",
          
          }
          }
          onSubmit={(valores, { resetForm }) => {
            console.log("Valores", valores);
          }
          }
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (

            <Form onSubmit={handleSubmit} noValidate className='mt-3'>
              <Row className="mb-3">
                <Form.Group as={Col} className="position-relative" >
                    <Form.Label style={{textAlign: "center"}}>Ingrese el número de referencia</Form.Label>
                    <Form.Control 
                    type="text"
                    name="buscar"
                    value={values.buscar}
                    onChange={handleChange}
                    isValid={touched.buscar && !errors.buscar}
                    isInvalid={touched.buscar && !!errors.buscar}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>{errors.buscar}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="d-grid gap-2">

                <Button type="submit" >Buscar</Button>
                </Form.Group>
              </Row>
             
            </Form>

          )}


        </Formik>
      </div>
    </>
  )
}
