import React, { useState } from 'react'
import { Row, Col, Form, Button } from "react-bootstrap"
import {  useFormik } from 'formik';
import * as yup from 'yup';
import { View } from '@aws-amplify/ui-react';

export const FormCapturaFlotilla = () => {

    const validationSchema = yup.object().shape({
        personaMoral: yup.string().required("Este campo es obligatorio"),
        numVehiculos: yup.string().required("Este campo es obligatorio"),

    })
    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            console.log(values);
        },
        initialValues: {
            personaMoral: "",
            numVehiculos: "",
        }
    });

    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Alta de Flotillas Foráneas</View>
                <View className='FormSubTitle'>Captura de Flotilla</View>
                <View className='FormDescripcion'>
                    A personas fisicas, personas morales, unidades economicas y entidades gubernamentales que generen
                    emisiones de gases de efecto invernadero en sus procesos productivos y operaciones dentro del
                    territorio del Estado de Durango, México.
                </View>
            </div>

            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Form noValidate onSubmit={formik.handleSubmit} className='ViewActa'>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Persona Moral (Personalidad)*</Form.Label>
                                <Form.Select
                                    type='text'
                                    name="personaMoral"
                                    value={formik.values.personaMoral}
                                    onChange={formik.handleChange}
                                    isValid={formik.touched.personaMoral && !formik.errors.personaMoral}
                                    isInvalid={formik.touched.personaMoral && !!formik.errors.personaMoral}

                                >
                                    <option value="">Selecciona</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </Form.Select>
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.personaMoral}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='position-relative'>
                                <Form.Label>Número de Vehiculos*</Form.Label>
                                <Form.Select
                                    type='text'
                                    name='numVehiculos'
                                    value={formik.values.numVehiculos}
                                    onChange={formik.handleChange}
                                    isValid={formik.touched.numVehiculos && !formik.errors.numVehiculos}
                                    isInvalid={formik.touched.numVehiculos && !!formik.errors.numVehiculos}
                                >
                                    <option value="">Selecciona</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    </Form.Select>
                                <Form.Control.Feedback type='invalid' tooltip>{formik.errors.numVehiculos}</Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                        <Row className='mb-3'>
                            <Form.Group as={Col} className='d-grid gap-2'>
                                <Button type='submit' variant='secondary'>Siguiente</Button>
                            </Form.Group>
                        </Row>
                        <Row className='mb-3'>
                            <Form.Group as={Col} className='d-grid gap-2'>
                                <Button type='submit' variant='link'>Regresar</Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </View>
            </View>
        </>
    )
}
